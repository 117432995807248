import { Flex, Grid, HStack } from '@chakra-ui/react';
import lowerCase from 'lodash/lowerCase';
import { Button } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { ThumbDownIcon } from '../../../../../../../../icons/ThumbDownIcon';
import { useViewJobCandidateDetailsStageTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';

function ViewJobCandidateDetailsDisqualificationDetails() {
  const { jobCandidate, disqualificationReason, revertDisqualifyCandidate } =
    useViewJobCandidateDetailsStageTasksWidgetContext();

  return (
    <>
      <Flex
        h={8}
        gap={2}
        w="full"
        color="gray.700"
        alignItems="center"
        borderRadius="base"
        fontWeight="normal"
      >
        <Flex
          w={6}
          h={6}
          pb="9px"
          pt="11px"
          bg="red.100"
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
        >
          <ThumbDownIcon color="red.500" w={3} h={3} />
        </Flex>

        <Flex gap={1} alignItems="baseline">
          <Text textStyle="body1Medium">
            {jobCandidate.firstName ?? jobCandidate.name.split(' ')[0]}
          </Text>

          <Text textStyle="body2Medium" color="primary.500">
            {lowerCase(disqualificationReason)}
          </Text>
        </Flex>
      </Flex>

      <Flex h={6} position="relative">
        <Grid
          gap={2}
          gridTemplateColumns="max-content 1fr"
          alignItems="center"
          justifyContent="center"
        >
          <HStack flexGrow={0}>
            <ClockIcon w={4} h={4} />
            <Text textStyle="body2Regular">3 days ago</Text>
          </HStack>

          <Button
            size="small"
            minH={6}
            h={6}
            hierarchy="secondary"
            onClick={() => revertDisqualifyCandidate()}
          >
            Revert
          </Button>
        </Grid>
      </Flex>
    </>
  );
}

export default ViewJobCandidateDetailsDisqualificationDetails;
