import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { DepartmentNanoId } from '../departments/departmentsTypes';
import { HiringPortalSourcingJobCandidateDetailsRoutes } from './HiringPortalSourcingJobCandidateDetailsRoutes';
import { JobNanoId } from '../jobs/jobsTypes/jobsFields';

const basePath = (
  departmentNanoId: DepartmentNanoId,
  jobCandidateNanoId: JobCandidateNanoId,
  jobNanoId: JobNanoId
) =>
  HiringPortalSourcingJobCandidateDetailsRoutes.activities(
    departmentNanoId,
    jobCandidateNanoId,
    jobNanoId
  );

export class HiringPortalSourcingJobCandidateDetailsActivitiesRoutes {
  static index(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId, jobNanoId)}`;
  }

  static all(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/all-activities`;
  }

  static phoneCalls(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/phone-calls`;
  }

  static callConnections(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/call-connections`;
  }

  static emails(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/emails`;
  }

  static meetings(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/meetings`;
  }

  static stageChanges(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/stage-changes`;
  }

  static comments(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/comments`;
  }

  static tasks(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId, jobNanoId)}/tasks`;
  }
}
