import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Text } from '../../../../../../helpers/Text';
import {
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { WorkflowStage } from '../../../../components/modals/ViewJobCandidateModal/helpers/VewiJobCandidateModalHeader/ViewJobCandidateModalHeader.types';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import {
  ViewJobCandidateDetailsCandidate,
  ViewJobCandidateDetailsJobType
} from '../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import { ViewJobCandidateDetailsProfileOverviewAccess } from './components/ViewJobCandidateDetailsProfileOverviewAccess';
import { ViewJobCandidateDetailsProfileOverviewActivities } from './components/ViewJobCandidateDetailsProfileOverviewActivities';
import { ViewJobCandidateDetailsProfileOverviewCalendar } from './components/ViewJobCandidateDetailsProfileOverviewCalendar';
import { ViewJobCandidateDetailsProfileOverviewMyNxmoov } from './components/ViewJobCandidateDetailsProfileOverviewMyNxmoov';
import { ViewJobCandidateDetailsProfileOverviewPreferences } from './components/ViewJobCandidateDetailsProfileOverviewPreferences';
import { ViewJobCandidateDetailsProfileOverviewQualifications } from './components/ViewJobCandidateDetailsProfileOverviewQualifications';
import { ViewJobCandidateDetailsProfileOverviewSource } from './components/ViewJobCandidateDetailsProfileOverviewSource';
import { ViewJobCandidateDetailsProfileOverviewTasks } from './components/ViewJobCandidateDetailsProfileOverviewTasks';
import { IsDisabled } from '../../../../../../types';

interface ViewJobCandidateDetailsProfileOverviewProps {
  isOpen: boolean;
  prospect?: boolean;
  onToggle: () => void;
  isDisabled?: IsDisabled;
  isHiringPortalPage: boolean;
  workflowStages: WorkflowStage[];
  departmentNanoId?: DepartmentNanoId;
  jobCandidate: ViewJobCandidateDetailsCandidate;
  job: ViewJobCandidateDetailsJobType;
}

export default function ViewJobCandidateDetailsProfileOverview({
  job,
  isOpen,
  prospect,
  onToggle,
  isDisabled,
  jobCandidate,
  isHiringPortalPage,
  departmentNanoId
}: ViewJobCandidateDetailsProfileOverviewProps) {
  const overviewContainerRef = useRef<HTMLDivElement>(null);
  const sourceRef = useRef<HTMLDivElement>(null);
  const { sharedFields } = useViewJobCandidateDetailsSubmissionContext();

  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (sourceRef.current) {
          sourceRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <Stack
      top="120px"
      spacing={0}
      overflow="auto"
      position="sticky"
      overflowX="hidden"
      hidden={!!isDisabled}
      transitionDuration="slow"
      ref={overviewContainerRef}
      height="calc(100vh - 193px)"
      overflowY={isOpen && isHovered ? 'auto' : 'hidden'}
      onMouseOver={isOpen ? onMouseHover : undefined}
      onMouseLeave={isOpen ? onMouseLeave : undefined}
      minW={isOpen ? 352 : 6}
      w={isOpen ? 352 : 6}
      flexShrink={0}
    >
      <Stack
        pos="relative"
        spacing={0}
        _before={
          isDisabled
            ? {
                h: '100%',
                w: '100%',
                top: '0',
                left: '0',
                zIndex: 9,
                content: '""',
                cursor: 'not-allowed',
                pos: 'absolute'
              }
            : undefined
        }
        __css={
          isDisabled
            ? {
                '*': {
                  pointerEvents: 'none'
                }
              }
            : undefined
        }
      >
        {!isDisabled && (
          <Flex
            w="full"
            overflow="hidden"
            h={isHovered || !isOpen ? 10 : 0}
            transitionDuration="slow"
            alignItems="flex-start"
          >
            <Button
              p={0}
              minW={0}
              left={0}
              zIndex={1}
              display="flex"
              hierarchy="link"
              onClick={onToggle}
              _hover={{
                textDecoration: 'none'
              }}
            >
              <Flex
                w={6}
                h={6}
                transitionDuration="slow"
                bg={isOpen ? 'primary.500' : 'gray.200'}
                borderRadius="sm"
                p="1px"
                _before={{
                  w: 2.5,
                  bg: 'white',
                  content: "''",
                  position: 'relative',
                  borderRadius: 'sm',
                  left: isOpen ? 3 : 0
                }}
              />

              <Text
                pl={3}
                color="gray.700"
                textStyle="body1Regular"
                opacity={isOpen ? 1 : 0}
                display={isOpen ? 'block' : 'none'}
                visibility={isOpen ? 'visible' : 'hidden'}
              >
                Profile Overview
              </Text>
            </Button>
          </Flex>
        )}

        <Stack
          w="340px"
          spacing={4}
          position="relative"
          left={isOpen ? 0 : '-340px'}
          transitionDuration="slow"
        >
          <ViewJobCandidateDetailsProfileOverviewCalendar />

          <ViewJobCandidateDetailsProfileOverviewTasks
            jobCandidate={jobCandidate}
            isHiringPortalPage={isHiringPortalPage}
            prospect={prospect}
            departmentNanoId={departmentNanoId}
            jobNanoId={job.nanoId}
          />

          <ViewJobCandidateDetailsProfileOverviewSource
            jobCandidate={jobCandidate}
          />

          {sharedFields.includes(
            CandidateSubmissionShareSettingsFields.AssessmentQualifications as CandidateSubmissionShareSettingsField
          ) && (
            <ViewJobCandidateDetailsProfileOverviewQualifications
              jobCandidate={jobCandidate}
              job={job}
            />
          )}

          {sharedFields.includes(
            CandidateSubmissionShareSettingsFields.AssessmentPreferences as CandidateSubmissionShareSettingsField
          ) && (
            <ViewJobCandidateDetailsProfileOverviewPreferences
              jobCandidate={jobCandidate}
              job={job}
            />
          )}

          <ViewJobCandidateDetailsProfileOverviewMyNxmoov
            jobCandidate={jobCandidate}
            job={job}
          />

          <ViewJobCandidateDetailsProfileOverviewAccess />

          <ViewJobCandidateDetailsProfileOverviewActivities />
        </Stack>
      </Stack>
    </Stack>
  );
}
