import {
  DateTime,
  FieldBrand,
  ItemCreatedAt,
  ItemID,
  ItemNanoId,
  ItemUpdatedAt,
  ItemUUID,
  MayBe
} from '../../../types';
import {
  JobCandidateId,
  JobCandidateName
} from '../../jobCandidates/jobCandidatesTypes';

import {
  UserEmail,
  UserFullName,
  UserId,
  UserImage,
  UserName,
  UserNanoId,
  UserRoles
} from '../../users/usersTypes';

export type JobCandidateAssessmentId = FieldBrand<
  ItemID,
  'JobCandidateAssessmentId'
>;
export type JobCandidateAssessmentUuid = FieldBrand<
  ItemUUID,
  'JobCandidateAssessmentUuid'
>;
export type MayBeJobCandidateAssessmentUuid = MayBe<JobCandidateAssessmentUuid>;
export type JobCandidateAssessmentNanoId = FieldBrand<
  ItemNanoId,
  'JobCandidateAssessmentNanoId'
>;
export type MayBeJobCandidateAssessmentNanoId =
  MayBe<JobCandidateAssessmentNanoId>;
export type JobCandidateAssessmentCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobCandidateAssessmentCreatedAt'
>;
export type JobCandidateAssessmentUpdatedAt = FieldBrand<
  ItemUpdatedAt,
  'JobCandidateAssessmentUpdatedAt'
>;
export type JobCandidateAssessmentSentAt = FieldBrand<
  DateTime,
  'JobCandidateAssessmentSentAt'
>;
export type MayBeJobCandidateAssessmentSentAt =
  MayBe<JobCandidateAssessmentSentAt>;
export type JobCandidateAssessmentUserId = FieldBrand<
  UserId,
  'JobCandidateAssessmentUserId'
>;
export type JobCandidateAssessmentUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'JobCandidateAssessmentUser'
>;
export type JobCandidateAssessmentStatus = FieldBrand<
  string,
  'JobCandidateAssessmentStatus'
>;
export type JobCandidateAssessmentJobCandidateId = FieldBrand<
  JobCandidateId,
  'JobCandidateAssessmentJobCandidateId'
>;
export type JobCandidateAssessmentJobCandidate = FieldBrand<
  { id: JobCandidateId; name: JobCandidateName },
  'JobCandidateAssessmentJobCandidateIds'
>;
export type JobCandidateAssessmentMessage = FieldBrand<
  string,
  'JobCandidateAssessmentMessage'
>;
export type JobCandidateAssessmentPages = FieldBrand<
  string[],
  'JobCandidateAssessmentPages'
>;
export type JobCandidateAssessmentSettingsPages = FieldBrand<
  {
    [K in JobCandidateAssessmentPageEnums]: { required: boolean };
  },
  'JobCandidateAssessmentSettingsPages'
>;
export type JobCandidateAssessmentSettings = FieldBrand<
  {
    pages: JobCandidateAssessmentSettingsPages;
    message: JobCandidateAssessmentMessage;
  },
  'JobCandidateAssessmentSettings'
>;

export const enum JobCandidateAssessmentPageEnums {
  SKILLS = 'skills',
  PROJECTS = 'projects',
  QUESTIONS = 'questions',
  REFERENCES = 'references',
  PREFERENCES = 'preferences',
  QUALIFICATIONS = 'qualifications',
  UPDATED_RESUME = 'updatedResume'
}

export const enum JobCandidateAssessmentFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  USER_ID = 'userId',
  USER = 'user',
  STATUS = 'status',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_CANDIDATE = 'jobCandidate',
  MESSAGE = 'message',
  PAGES = 'pages',
  SETTINGS = 'settings',
  SENT_AT = 'sentAt',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}
