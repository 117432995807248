import { DepartmentNanoId } from '../departments/departmentsTypes';
import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../jobs/jobsTypes';
import { TalentPoolNanoId } from '../talentPools/talentPoolsTypes';
import { HiringPortalRoutes } from './HiringPortalRoutes';

const baseSourcingPath = HiringPortalRoutes.sourcing();
const baseProspectsPath = `${baseSourcingPath}/prospects`;

export class HiringPortalSourcingRoutes {
  static index() {
    return baseSourcingPath;
  }

  static prospects() {
    return baseProspectsPath;
  }

  static addProspect() {
    return `${baseProspectsPath}/add`;
  }

  static addProspectTalents(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/add/${poolNanoId}/talents`;
  }

  static addProspectIntake(poolNanoId: TalentPoolNanoId) {
    return `${baseProspectsPath}/add/${poolNanoId}/intake`;
  }

  static viewPool(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/${poolNanoId}`;
  }

  static viewProspectDetails(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/${poolNanoId}/details`;
  }

  static viewIndex() {
    return `${baseProspectsPath}/view`;
  }

  static viewDepartment(departmentNanoId: DepartmentNanoId) {
    return `${baseProspectsPath}/view/${departmentNanoId}`;
  }

  static viewPerson(
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${baseProspectsPath}/view/${departmentNanoId}/${jobNanoId}/${personNanoId}`;
  }

  static viewJobDetails(
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${baseProspectsPath}/view/${departmentNanoId}/${jobNanoId}/${personNanoId}/job-details`;
  }

  static viewPersonDetails(
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId,
    detail: string
  ) {
    return `${baseProspectsPath}/view/${departmentNanoId}/${jobNanoId}/${personNanoId}/${detail}`;
  }
}
