import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from '@chakra-ui/form-control';
import { Flex } from '@chakra-ui/layout';
import { SystemProps } from '@chakra-ui/react';
import {
  GroupBase,
  InputActionMeta,
  MenuPlacement,
  OnChangeValue,
  Props as ChakraSelectProps,
  Select as ReactSelect,
  SingleValue
} from 'chakra-react-select';
import find from 'lodash/find';
import {
  forwardRef,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  ForwardedRef
} from 'react';

import { ReactSelectMenuButton } from '../../../../../ReactSelectMenuButton';

import { SelectFieldFormatOptionLabel } from '../../helpers/SelectFieldFormatOptionLabel';

import { toCamelCase } from '../../../../../../utils/toCamelCase';

import {
  SelectFieldControlOnInputChange,
  SelectFieldRefInstance,
  SelectOptions,
  SelectOptionType,
  SelectOptionWithImageType
} from './SelectFieldControl.types';
import { ButtonHierarchyType } from '../../../../../Button';

interface SelectFieldControlProps extends Omit<ChakraSelectProps, 'onChange'> {
  id?: string;
  label?: string;
  labelAddon?: ReactNode;
  hasError?: boolean;
  errorMessage?: string | null;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  helperText?: string;
  placeholder?: ReactNode;
  i18nPlaceholder?: string;
  withImages?: boolean;
  isSearchable?: boolean;
  withAddNewButton?: boolean;
  addNewButtonLabel?: string;
  addNewButtonHierarchy?: ButtonHierarchyType;
  addNewButtonDisabled?: boolean;
  addNewButtonLoading?: boolean;
  addNewButtonWithoutPlusIcon?: boolean;
  addNewButtonAction?: () => void;
  addNewButtonToValueAction?: () => Promise<SelectOptionType | undefined>;
  defaultValue?: SelectOptionType;
  selectedValue?: SelectOptionType;
  options: SelectOptions;
  onInputChange?: SelectFieldControlOnInputChange;
  onChange: (
    value: string | number | null,
    selectedOption?: SelectOptionType
  ) => void;
  autoFocus?: boolean;
  onBlur?: () => void;
  menuPlacement?: MenuPlacement;
  w?: SystemProps['width'];
  h?: SystemProps['height'];
  reactSelectStyles?: Record<string, unknown>;
  showDropdownIndicator?: boolean;
  visibility?: SystemProps['visibility'];
  unsetValue?: boolean;
}

const SelectFieldControl = forwardRef<
  SelectFieldRefInstance,
  SelectFieldControlProps
>(
  (
    {
      id,
      label,
      labelAddon,
      size = 'md',
      isRequired,
      isDisabled,
      isLoading,
      hasError,
      errorMessage,
      isReadOnly,
      menuIsOpen,
      isSearchable,
      helperText,
      i18nPlaceholder,
      placeholder,
      withImages,
      withAddNewButton,
      addNewButtonLabel,
      addNewButtonHierarchy,
      addNewButtonWithoutPlusIcon,
      addNewButtonDisabled,
      addNewButtonLoading,
      addNewButtonAction,
      addNewButtonToValueAction,
      options,
      defaultValue,
      selectedValue,
      value,
      onInputChange,
      onChange,
      autoFocus,
      onBlur,
      menuPlacement = 'auto',
      w,
      h,
      reactSelectStyles,
      showDropdownIndicator = false,
      visibility,
      variant,
      unsetValue,
      name
    }: SelectFieldControlProps,
    ref: ForwardedRef<SelectFieldRefInstance>
  ) => {
    const [selectedFieldValue, setSelectedValue] = useState<
      SingleValue<SelectOptionType> | undefined
    >(selectedValue || defaultValue);
    const [inputValue, setInputValue] = useState('');

    if (menuIsOpen) {
      console.log('SelectFieldControl', { menuIsOpen });
    }
    const handleInputChange = useCallback<
      (updatedValue: string, actionMeta: InputActionMeta) => void
    >(
      (updatedValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
          setInputValue(updatedValue);
          return onInputChange?.(updatedValue, actionMeta);
        }

        return undefined;
      },
      [onInputChange]
    );

    const handleChange = useCallback<
      (newValue: OnChangeValue<SelectOptionType, false>) => void
    >(
      (newValue) => {
        setSelectedValue(newValue);
        setInputValue('');
        onChange(newValue?.value || null, newValue as SelectOptionType);
      },
      [onChange]
    );

    const [menuOpen, setMenuOpen] = useState(false);
    const [doc, setDocument] = useState<Document>();

    useEffect(() => {
      setDocument(document);
    }, []);

    const closeMenu = () => {
      setMenuOpen(false);
    };

    const addNewAction = useCallback(async () => {
      if (addNewButtonToValueAction) {
        const newSelectedValue = await addNewButtonToValueAction();
        if (newSelectedValue) {
          handleChange(newSelectedValue);
        }
      } else {
        addNewButtonAction && addNewButtonAction();
      }
      closeMenu();
    }, [addNewButtonAction, addNewButtonToValueAction, handleChange]);

    const handleKeyDown = useCallback<
      (event: KeyboardEvent<HTMLDivElement>) => void
    >(
      (event) => {
        if (event.key === 'Enter' && inputValue && withAddNewButton) {
          event.preventDefault();
          addNewAction();
        }
      },
      [inputValue, withAddNewButton, addNewAction]
    );

    useEffect(() => {
      if (selectedValue) {
        setSelectedValue(selectedValue);
      } else {
        const selectedValueIsDefaultValue = defaultValue?.value === value;

        const valueToSet = selectedValueIsDefaultValue
          ? defaultValue
          : find<SelectOptionType>(options, (option) => option.value === value);

        (selectedValueIsDefaultValue || value || unsetValue) &&
          setSelectedValue(unsetValue ? valueToSet || null : valueToSet);
      }
    }, [defaultValue, value, selectedValue, options, unsetValue]);

    const isInvalid =
      hasError ||
      (typeof errorMessage === 'string' && errorMessage.length !== 0);

    return (
      <FormControl
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        id={!id && label ? toCamelCase(label) : `${id}-control`}
        w={w}
        h={h}
        visibility={visibility}
        onBlur={onBlur}
      >
        {(label || labelAddon) && (
          <Flex alignItems="center">
            {label && <FormLabel display="flex">{label}</FormLabel>}

            {labelAddon && (
              <FormLabel
                mr={0}
                ml="auto"
                display="flex"
                as="aside"
                alignItems="center"
                requiredIndicator={<></>}
              >
                {labelAddon}
              </FormLabel>
            )}
          </Flex>
        )}

        <ReactSelect<
          SelectOptionType | SelectOptionWithImageType,
          false,
          GroupBase<SelectOptionType | SelectOptionWithImageType>
        >
          inputId={`${id}-input`}
          ref={ref}
          id={id}
          name={name}
          variant={variant}
          isInvalid={isInvalid}
          className="form-select-class"
          placeholder={i18nPlaceholder || placeholder}
          onInputChange={handleInputChange}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          focusBorderColor="primary.500"
          selectedOptionStyle="color"
          menuIsOpen={menuOpen}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={closeMenu}
          formatOptionLabel={(data) => (
            <SelectFieldFormatOptionLabel
              {...{ ...data }}
              withImages={withImages}
            />
          )}
          size={size}
          components={
            withAddNewButton
              ? {
                  MenuList: (props) =>
                    ReactSelectMenuButton({
                      ...props,
                      buttonLabel: addNewButtonLabel,
                      buttonAction: addNewAction,
                      buttonDisabled: addNewButtonDisabled,
                      buttonLoading: addNewButtonLoading,
                      buttonHierarchy: addNewButtonHierarchy,
                      withoutPlusIcon: addNewButtonWithoutPlusIcon
                    })
                }
              : undefined
          }
          isDisabled={isDisabled}
          isLoading={isLoading}
          isSearchable={isSearchable}
          menuPlacement={menuPlacement}
          menuShouldScrollIntoView
          selectedOptionColor="primary"
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          value={selectedFieldValue}
          // value={
          //   selectedValue ||
          //   find<SelectOptionType>(options, (option) => option.value === value)
          // }
          inputValue={inputValue}
          menuPortalTarget={doc?.body}
          menuPosition="fixed"
          styles={{
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999
            }),
            menu: (provided) => ({ ...provided, zIndex: 9999 })
          }}
          options={options}
          chakraStyles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              bg: 'transparent',
              display: showDropdownIndicator ? 'flex' : 'none',
              w: 6,
              color: 'gray.500',
              transition: 'slow',
              transform: 'auto',
              rotate: menuOpen ? '180deg' : '0deg'
            }),
            option: (provided) => ({
              ...provided,
              h: 10,
              px: 4,
              wordBreak: 'break-all'
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none'
            }),
            placeholder: (provided) => ({
              ...provided,
              color: 'gray.500'
            }),
            ...reactSelectStyles
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: '#0580A4'
            }
          })}
        />

        {errorMessage ? (
          <FormErrorMessage wordBreak="break-all">
            {errorMessage}
          </FormErrorMessage>
        ) : (
          helperText && <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

SelectFieldControl.displayName = 'SelectFieldControl';

export default SelectFieldControl;
