import {
  ButtonGroup,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  RadioGroup,
  Stack,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import compact from 'lodash/compact';
import truncate from 'lodash/truncate';
import { useCallback, useState } from 'react';
import { AvatarHelper } from '../../../../../../../../helpers/AvatarHelper';
import { Button } from '../../../../../../../../helpers/Button';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { RadioField } from '../../../../../../../../helpers/forms/formFields/RadioField';
import { TextareaField } from '../../../../../../../../helpers/forms/formFields/TextareaField';
import { useChakraToast } from '../../../../../../../../helpers/useChakraToast';
import { JobsIcon } from '../../../../../../../../icons/JobsIcon';
import { ThumbDownIcon } from '../../../../../../../../icons/ThumbDownIcon';
import { JobCandidateLabelHelper } from '../../../../../../../jobCandidates/helpers/JobCandidateLabelHelper';
import {
  JobCandidateDisqualificationReason,
  JobCandidateDisqualificationReasons,
  JobCandidateFirstName,
  JobCandidateId,
  JobCandidateJobTitle,
  JobCandidateLastName,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateSearchTypeLabel,
  MayBeJobCandidateCompany,
  MayBeJobCandidateImage
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { renderJobCandidateName } from '../../../../../../../jobCandidates/utils/renderJobCandidateName';
import { JobLocationHelper } from '../../../../../../helpers/JobLocationHelper';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../../../jobsTypes';

interface ViewJobCandidateDetailsDisqualifyCandidateProps {
  isOpen: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
  jobCandidate: {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    firstName?: JobCandidateFirstName;
    lastName?: JobCandidateLastName;
    jobTitle: JobCandidateJobTitle;
    company: MayBeJobCandidateCompany;
    image: MayBeJobCandidateImage;
    searchTypeLabel: JobCandidateSearchTypeLabel;
  };
  onClose: () => void;
  disqualifyCandidate: (reason: string) => void;
}

const ViewJobCandidateDetailsDisqualifyCandidate = ({
  job,
  isOpen,
  onClose,
  jobCandidate,
  disqualifyCandidate
}: ViewJobCandidateDetailsDisqualifyCandidateProps) => {
  const toast = useChakraToast();

  const mockDiscontinueReasons = [
    { label: 'Reviewed | Not Selected', value: 'reviewed-not-selected' },
    { label: 'Interviewed | Not Selected', value: 'interviewed-not-selected' },
    { label: 'Candidate Not Interested', value: 'candidate-not-interested' },
    { label: 'Candidate Withdrew', value: 'candidate-withdrew' },
    { label: 'Do Not Contact', value: 'do-not-contact' }
  ];
  const [disqualificationReason, setDisqualificationReason] =
    useState<string>('');

  const handleClose = useCallback(() => {
    setDisqualificationReason(
      JobCandidateDisqualificationReasons.NONE as JobCandidateDisqualificationReason
    );

    onClose();
  }, [onClose]);

  const handleDisqualifyCandidate = useCallback(() => {
    disqualifyCandidate(disqualificationReason);
    toast.closeAll();

    toast({
      title: `Candidate disqualified`,
      status: 'success',
      position: 'top-right',
      duration: 2000,
      isClosable: true
    });

    onClose();
  }, [disqualificationReason, disqualifyCandidate, toast, onClose]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />

        <ModalContent borderRadius="none">
          <ModalHeader fontWeight="semibold" fontSize="md" bg="gray.100">
            <Flex gap={3} flexDir="row" alignItems="center">
              <Text as="span">{job.name}</Text>

              {job.cities?.length !== 0 ? (
                <JobLocationHelper job={job} withIcon />
              ) : null}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody py={6}>
            <Stack spacing={3} divider={<StackDivider />}>
              <Flex alignItems="center">
                <HStack flex={1} spacing={3}>
                  <AvatarHelper size="md" image={jobCandidate.image} />

                  <VStack spacing={0} alignItems="start">
                    <HStack spacing={2}>
                      <Heading
                        level="h3"
                        noOfLines={1}
                        wordBreak="break-all"
                        color="gray.900"
                      >
                        {renderJobCandidateName(jobCandidate)}
                      </Heading>

                      <JobCandidateLabelHelper
                        searchTypeLabel={jobCandidate.searchTypeLabel}
                      />
                    </HStack>

                    <HStack direction="row" spacing={2}>
                      <JobsIcon w="20px" h="20px" fill="gray.500" />

                      <Text
                        textStyle="body1Regular"
                        wordBreak="break-all"
                        color="gray.900"
                        fontWeight={400}
                        noOfLines={1}
                      >
                        {compact([
                          truncate(jobCandidate.jobTitle, {
                            length: 35,
                            omission: '...'
                          }),
                          jobCandidate.company?.name
                        ]).join(' @ ')}
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>

                <Flex
                  h={8}
                  w={8}
                  minW={0}
                  minH={0}
                  display="flex"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                  bg="red.100"
                >
                  <ThumbDownIcon w={3.5} h={3.5} color="red.500" />
                </Flex>
              </Flex>

              <Stack spacing={6}>
                <RadioGroup
                  name="disqualificationReason"
                  onChange={(value) =>
                    setDisqualificationReason(
                      value as JobCandidateDisqualificationReason
                    )
                  }
                  value={disqualificationReason + ''}
                >
                  <Flex gap={3} flexDir="column">
                    <Text color="gray.900" fontSize="md" fontWeight="medium">
                      Discontinue
                    </Text>

                    {mockDiscontinueReasons.map((action) => (
                      <Flex key={action.value}>
                        <RadioField
                          size="small"
                          value={action.value + ''}
                          colorScheme="primary"
                          key={action.value}
                        >
                          <Text color="gray.900" textStyle="body1Regular">
                            {action.label}
                          </Text>
                        </RadioField>
                      </Flex>
                    ))}
                  </Flex>
                </RadioGroup>

                <TextareaField label="Message" rows={5} />

                <ButtonGroup w="full" spacing={4}>
                  <Button
                    w="full"
                    size="medium"
                    hierarchy="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    w="full"
                    size="medium"
                    type="submit"
                    onClick={handleDisqualifyCandidate}
                  >
                    Continue
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewJobCandidateDetailsDisqualifyCandidate;
