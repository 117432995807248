import { Box } from '@chakra-ui/react';
import { ReactNode, RefObject, useEffect } from 'react';
import {
  JobCandidateCandidateShares,
  JobCandidateCompanyPhone,
  JobCandidatePhone,
  MayBeJobCandidateCity
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../jobsTypes';
import { type ViewJobCandidateDetailsCandidate } from '../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import { ViewJobCandidateDetailsHeader } from '../ViewJobCandidateDetailsHeader';
import { ViewJobCandidateDetailsPageTabs } from '../ViewJobCandidateDetailsPageTabs';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { IsDisabled } from '../../../../../../types';

interface ViewJobCandidateDetailsCandidateDetailsProps {
  prospect?: boolean;
  children: ReactNode;
  isBasePath?: boolean;
  isDisabled?: IsDisabled;
  requiresCredit: boolean;
  isJobDetailsPage?: boolean;
  isHiringPortalPath: boolean;
  leftSideBarIsOpen?: boolean;
  leftSideBarOnOpen?: () => void;
  leftSideBarOnClose?: () => void;
  departmentNanoId?: DepartmentNanoId;
  isBioOutsideViewport?: RefObject<HTMLDivElement>;
  jobCandidate: ViewJobCandidateDetailsCandidate & {
    candidateShares: JobCandidateCandidateShares;
    city: MayBeJobCandidateCity;
    companyPhone: JobCandidateCompanyPhone;
    phone: JobCandidatePhone;
  };
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
}

function ViewJobCandidateDetailsCandidateDetails({
  job,
  children,
  prospect,
  isDisabled,
  isBasePath,
  jobCandidate,
  requiresCredit,
  isJobDetailsPage,
  departmentNanoId,
  leftSideBarOnOpen,
  leftSideBarOnClose,
  isHiringPortalPath,
  isBioOutsideViewport
}: ViewJobCandidateDetailsCandidateDetailsProps) {
  const { isCandidateSubmissionOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen || isSendEmailAssessmentOpen;

  useEffect(() => {
    if (isRightSidebarOpen) {
      leftSideBarOnClose && leftSideBarOnClose();
    } else {
      leftSideBarOnOpen && leftSideBarOnOpen();
    }
  }, [isRightSidebarOpen, leftSideBarOnOpen, leftSideBarOnClose]);

  return (
    <Box pos="relative">
      {isJobDetailsPage ? null : (
        <ViewJobCandidateDetailsHeader
          isBioOutsideViewport={isBioOutsideViewport}
          jobCandidateNanoId={jobCandidate.nanoId}
          isHiringPortalPath={isHiringPortalPath}
          departmentNanoId={departmentNanoId}
          requiresCredit={requiresCredit}
          jobCandidate={jobCandidate}
          isProspectPath={prospect}
          isDisabled={isDisabled}
          isBasePath={isBasePath}
          job={job}
        />
      )}

      <ViewJobCandidateDetailsPageTabs
        isHiringPortalPath={isHiringPortalPath}
        departmentNanoId={departmentNanoId}
        isJobDetailsPage={isJobDetailsPage}
        jobCandidate={jobCandidate}
        isDisabled={isDisabled}
        isBasePath={isBasePath}
        prospect={prospect}
        job={job}
      >
        {children}
      </ViewJobCandidateDetailsPageTabs>
    </Box>
  );
}

export default ViewJobCandidateDetailsCandidateDetails;
