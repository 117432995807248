import { Flex, HStack, StackDivider, useDisclosure } from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { MouseEvent } from 'react';

import { Button } from '../../../../../../../../helpers/Button';
import { ScheduleHelperModal } from '../../../../../../../../helpers/ScheduleHelper/modal/ScheduleHelperModal';
import { Text } from '../../../../../../../../helpers/Text';
import { useChakraToast } from '../../../../../../../../helpers/useChakraToast';
import { ThumbDownIcon } from '../../../../../../../../icons/ThumbDownIcon';
import { ThumbUpIcon } from '../../../../../../../../icons/ThumbUpIcon';
import {
  JobCandidateFirstName,
  JobCandidateId,
  JobCandidateJobTitle,
  JobCandidateLastName,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateSearchTypeLabel,
  MayBeJobCandidateCompany,
  MayBeJobCandidateImage
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { WorkflowStagesCache } from '../../../../../../../workflowStages/WorkflowStagesCache';
import { usePaginatedWorkflowStages } from '../../../../../../../workflowStages/hooks/usePaginatedWorkflowStages';
import {
  fetchWorkflowStagesQuery,
  FetchWorkflowStagesResponse
} from '../../../../../../../workflowStages/queries/fetchWorkflowStages.query';
import {
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  WorkflowStageFields
} from '../../../../../../../workflowStages/workflowStagesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../../../jobsTypes';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageWorkflowStage
} from '../../../../ViewJobCandidateDetailsPage.types';
import { useViewJobCandidateDetailsStageTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';
import { ViewJobCandidateDetailsStageSection } from '../.././components/ViewJobCandidateDetailsStageSection';
import { ViewJobCandidateDetailsTasksSection } from '../.././components/ViewJobCandidateDetailsTasksSection';
import { ViewJobCandidateDetailsTaskRequireAttention } from '../../components/ViewJobCandidateDetailsTaskRequireAttention';
import { ViewJobCandidateInterviewScoreCardModal } from '../../components/ViewJobCandidateInterviewScoreCardModal';
import { ViewJobCandidateDetailsDisqualifyCandidate } from '../ViewJobCandidateDetailsDisqualifyCandidate';

interface ViewJobCandidateDetailsStageTasksManagerWrapperProps {
  jobCandidateNanoId: JobCandidateNanoId;
  requiresCredit?: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
  jobCandidate: {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    firstName?: JobCandidateFirstName;
    lastName?: JobCandidateLastName;
    jobTitle: JobCandidateJobTitle;
    company: MayBeJobCandidateCompany;
    image: MayBeJobCandidateImage;
    searchTypeLabel: JobCandidateSearchTypeLabel;
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
  jobCandidateDetails?: JobCandidateDetailsJobCandidate;
  isHiringPortalPath?: boolean;
  isCentered?: boolean;
}

export default function ViewJobCandidateDetailsStageTasksManagerWrapper({
  job,
  isCentered,
  jobCandidate,
  isHiringPortalPath,
  jobCandidateNanoId,
  requiresCredit,
  jobCandidateDetails
}: ViewJobCandidateDetailsStageTasksManagerWrapperProps) {
  const toast = useChakraToast();

  const {
    isOpenDisqualificationPopup,
    onOpenDisqualificationPopup,
    onCloseDisqualificationPopup
  } = useViewJobCandidateDetailsStageTasksWidgetContext();

  const {
    isOpen: isOpenScoreCard,
    onOpen: openScoreCard,
    onClose: closeScoreCard
  } = useDisclosure();

  const { workflowStages } =
    usePaginatedWorkflowStages<FetchWorkflowStagesResponse>({
      query: fetchWorkflowStagesQuery,
      cacheKey: WorkflowStagesCache.jobIndexCacheKey(job.nanoId),
      initialFilters: {
        [WorkflowStageFields.JOB_ID]: { operator: 'eq', value: job.id }
      } as unknown as FetchWorkflowStagesFilters,
      initialSort: {
        [WorkflowStageFields.ARRANGEMENT]: { ascending: true }
      } as unknown as FetchWorkflowStagesSort
    });

  const changeWorkflowStage = (e: MouseEvent<HTMLButtonElement>) => {
    const selectedStage = workflowStages.find(
      (stage) => stage.nanoId === e.currentTarget.value
    );

    if (selectedStage?.name === 'Interview') {
      openScoreCard();
    }

    toast({
      title: `${startCase(selectedStage?.name)} stage selected`,
      status: 'success',
      position: 'top-right',
      duration: 2000,
      isClosable: true
    });
  };

  const { isOpen: isOpenRequireAttention, onClose: onCloseRequireAttention } =
    useDisclosure({
      defaultIsOpen: true
    });

  const {
    isOpen: isOpenSchedulePopup,
    onOpen: onOpenSchedulePopup,
    onClose: onCloseSchedulePopup
  } = useDisclosure();

  return (
    <Flex pos="relative">
      {isHiringPortalPath ? (
        <HStack
          w="lg"
          pos="relative"
          borderRadius="base"
          transitionDuration="slow"
          justifyContent="flex-start"
          spacing={5}
        >
          <Button
            p={4}
            w="50%"
            h="auto"
            bg="gray.50"
            display="flex"
            color="red.500"
            flexDir="column"
            border="1px solid"
            borderColor="gray.200"
            onClick={onOpenDisqualificationPopup}
            justifyContent="stretch"
            hierarchy="tertiary"
            alignItems="center"
            boxShadow="none"
            gap={1}
            _hover={{
              bg: 'gray.100',
              borderColor: 'gray.300'
            }}
          >
            <ThumbDownIcon w={5} h={5} />
            <Text lineHeight="base">Discontinue</Text>
          </Button>

          <Button
            p={4}
            w="50%"
            h="auto"
            bg="gray.50"
            display="flex"
            color="green.600"
            flexDir="column"
            border="1px solid"
            hierarchy="tertiary"
            borderColor="gray.200"
            justifyContent="stretch"
            onClick={onOpenSchedulePopup}
            alignItems="center"
            boxShadow="none"
            gap={1}
            _hover={{
              bg: 'gray.100',
              borderColor: 'gray.300'
            }}
          >
            <ThumbUpIcon w={5} h={5} />
            <Text lineHeight="base">Advance</Text>
          </Button>
        </HStack>
      ) : (
        <Flex pos="relative">
          <HStack
            w="lg"
            spacing={0}
            bg="gray.50"
            pos="relative"
            border="1px solid"
            borderRadius="base"
            borderColor="gray.200"
            transitionDuration="slow"
            justifyContent="flex-start"
            visibility={isOpenRequireAttention ? 'hidden' : 'visible'}
            opacity={isOpenRequireAttention ? 0 : 1}
            divider={<StackDivider />}
          >
            <Flex justifyContent="stretch" w="50%">
              <ViewJobCandidateDetailsStageSection
                jobCandidate={jobCandidate}
                workflowStages={workflowStages}
                changeWorkflowStage={changeWorkflowStage}
              />
            </Flex>

            <Flex justifyContent="stretch" w="50%">
              <ViewJobCandidateDetailsTasksSection />
            </Flex>
          </HStack>

          <Flex
            w="lg"
            h="full"
            zIndex={9}
            pos="absolute"
            display={isOpenRequireAttention ? 'flex' : 'none'}
            alignItems={isCentered ? 'center' : 'flex-start'}
            justifyContent={isCentered ? 'center' : 'flex-end'}
          >
            <ViewJobCandidateDetailsTaskRequireAttention
              reopenTask={onCloseRequireAttention}
              jobCandidate={jobCandidate}
              status="overdue"
            />
          </Flex>
        </Flex>
      )}

      <ViewJobCandidateInterviewScoreCardModal
        jobCandidateNanoId={jobCandidateNanoId}
        requiresCredit={requiresCredit}
        jobCandidateDetails={jobCandidateDetails}
        isOpenScoreCard={isOpenScoreCard}
        closeScoreCard={closeScoreCard}
        job={job}
      />

      <ScheduleHelperModal
        minSlots={1}
        jobNanoId={job.nanoId}
        isOpen={isOpenSchedulePopup}
        onCancel={onCloseSchedulePopup}
        onSubmit={onCloseSchedulePopup}
        selectDateLabel="Interview Availability"
        title={`Interview for ${job.name} role`}
      />

      <ViewJobCandidateDetailsDisqualifyCandidate
        isOpen={isOpenDisqualificationPopup}
        onClose={onCloseDisqualificationPopup}
        disqualifyCandidate={console.log}
        jobCandidate={jobCandidate}
        job={job}
      />
    </Flex>
  );
}
