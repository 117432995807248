import {
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal
} from '@chakra-ui/react';
import first from 'lodash/first';
import size from 'lodash/size';

import { JobCity } from '../../jobsTypes';
import {
  JobLocationHelperContact,
  JobLocationHelperJob,
  JobLocationHelperJobCandidate,
  JobLocationHelperTalentPool
} from './JobLocationHelper.types';

import { DotIcon } from '../../../../icons/DotIcon';
import { LocationIcon } from '../../../../icons/LocationIcon';

import { Text } from '../../../../helpers/Text';

import { renderLocation } from '../../../common/utils/renderLocation';

import { TextProps } from '../../../../helpers/Text/Text';

interface JobLocationHelperContactProps {
  job?: never;
  talentPool?: never;
  jobCandidate?: never;
  contact: JobLocationHelperContact;
}

interface JobLocationHelperJobProps {
  job: JobLocationHelperJob;
  talentPool?: never;
  jobCandidate?: never;
  contact?: never;
}

interface JobLocationHelperTalentPoolProps {
  job?: never;
  talentPool: JobLocationHelperTalentPool;
  jobCandidate?: never;
  contact?: never;
}

interface JobLocationHelperJobCandidateProps {
  talentPool?: never;
  job?: never;
  jobCandidate: JobLocationHelperJobCandidate;
  contact?: never;
}

interface JobLocationHelperDefaultProps extends Omit<TextProps, 'children'> {
  withIcon?: boolean;
  withDash?: boolean;
}

type JobLocationHelperProps = JobLocationHelperDefaultProps &
  (
    | JobLocationHelperJobProps
    | JobLocationHelperTalentPoolProps
    | JobLocationHelperJobCandidateProps
    | JobLocationHelperContactProps
  );

function JobLocationHelper({
  job,
  jobCandidate,
  talentPool,
  contact,
  withIcon,
  withDash,
  textStyle = 'body1Regular',
  color = 'gray.900',
  ...textProps
}: JobLocationHelperProps) {
  const item = job || talentPool || jobCandidate || contact;
  const firstCity = (
    size(item.cities) ? first(item.cities) : item.city
  ) as JobCity;
  return firstCity ? (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack spacing={2} cursor={size(item.cities) > 1 ? 'pointer' : 'auto'}>
          {withIcon ? (
            <LocationIcon w="20px" h="20px" color="gray.500" />
          ) : null}
          <Text
            textStyle={textStyle}
            whiteSpace="nowrap"
            color={size(item.cities) > 1 ? 'primary.500' : color}
            {...textProps}
          >
            {renderLocation({ city: firstCity })}
            {size(item.cities) > 1 ? ' +' : null}
          </Text>
        </HStack>
      </PopoverTrigger>
      {size(item.cities) > 1 ? (
        <Portal>
          <PopoverContent w="200px">
            <PopoverBody>
              {item.cities?.map((city) => (
                <HStack spacing={1.5} key={city.id} alignItems="start">
                  <DotIcon color="gray.500" mt={2} />
                  <Text textStyle="body1Regular" color="gray.900">
                    {renderLocation({ city })}
                  </Text>
                </HStack>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      ) : null}
    </Popover>
  ) : withDash ? (
    <Text textStyle={textStyle} color={color} {...textProps}>
      -
    </Text>
  ) : null;
}

export default JobLocationHelper;
