import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidateAssessmentCacheKey,
  FetchJobCandidateAssessmentsCacheKey
} from './jobCandidateAssessmentsTypes';

export class JobCandidateAssessmentsCache {
  static indexCacheKey() {
    return 'jobCandidateAssessments' as FetchJobCandidateAssessmentsCacheKey;
  }

  static showCacheKey() {
    return 'jobCandidateAssessment' as FetchJobCandidateAssessmentCacheKey;
  }

  static jobCandidateIndexCacheKey(jobCandidateId: JobCandidateId) {
    return `jobCandidateAssessment-jobCandidate-${jobCandidateId}` as FetchJobCandidateAssessmentCacheKey;
  }
}
