import { Heading as ChakraHeading } from '@chakra-ui/layout';
import { HeadingProps } from './Heading.types';

const Heading = ({
  children,
  level,
  size,
  alignment,
  ...props
}: HeadingProps) => {
  return (
    <ChakraHeading
      as={level}
      textAlign={alignment}
      size={size ?? level}
      {...props}
    >
      {children}
    </ChakraHeading>
  );
};

export default Heading;
