import { Collapse, Flex, Stack, Switch } from '@chakra-ui/react';
import { FunctionComponent, ReactNode, useMemo } from 'react';
import { Text } from '../../../../../../../../helpers/Text';
import {
  useViewJobCandidateDetailsSendAssessmentContext,
  ViewJobCandidateDetailsSendAssessmentFields
} from '../../../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { IsDisabled } from '../../../../../../../../types';

type ViewJobCandidateDetailsAssessmentItemProps = {
  label: string;
  children?: ReactNode;
  isDisabled?: IsDisabled;
  icon?: FunctionComponent;
  value: ViewJobCandidateDetailsSendAssessmentFields;
};

export default function ViewJobCandidateDetailsAssessmentItem({
  icon,
  label,
  isDisabled,
  value,
  children
}: ViewJobCandidateDetailsAssessmentItemProps) {
  const { handleAssessmentField, assessmentFields } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isShared = useMemo(
    () => assessmentFields.includes(value),
    [assessmentFields, value]
  );

  const handleChange = () => {
    handleAssessmentField(value);
  };

  return (
    <Stack spacing={0}>
      <Flex
        px={2}
        gap={2}
        py={1.5}
        as="label"
        alignItems="center"
        transitionDuration="slow"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        _hover={{ bg: 'gray.100' }}
      >
        {icon && (
          <Flex
            w={4}
            h={4}
            as={icon}
            color="gray.600"
            fill="gray.600"
            opacity={isDisabled ? 0.5 : 1}
            transitionDuration="slow"
            transitionProperty="all"
          />
        )}

        <Text flex={1} opacity={isDisabled ? 0.5 : 1}>
          {label}
        </Text>

        <Switch
          value={value}
          name="sharedFields"
          isChecked={isShared}
          isDisabled={isDisabled}
          onChange={handleChange}
        />
      </Flex>

      {children && (
        <Collapse in={isShared}>
          <Stack spacing={1} pl={6}>
            {children}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
}
