import { Flex, Stack } from '@chakra-ui/react';
import { Button } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { AlertIcon } from '../../../../../../../../icons/AlertIcon';
import {
  JobCandidateFirstName,
  JobCandidateName,
  JobCandidateNanoId
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { JobCandidateDetailsPageWorkflowStage } from '../../../../ViewJobCandidateDetailsPage.types';

interface ViewJobCandidateDetailsTaskRequireAttentionprops {
  jobCandidate: {
    name: JobCandidateName;
    nanoId: JobCandidateNanoId;
    firstName?: JobCandidateFirstName;
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
  reopenTask: () => void;
  status?: 'overdue';
}

function ViewJobCandidateDetailsTaskRequireAttention({
  jobCandidate,
  reopenTask
}: ViewJobCandidateDetailsTaskRequireAttentionprops) {
  return (
    <>
      <Stack
        px={4}
        py={2}
        w="sm"
        spacing={1}
        rounded="lg"
        border="2px solid"
        borderLeftWidth="6px"
        borderColor="red.500"
        borderRadius="base"
        overflow="hidden"
      >
        <Flex alignItems="center" gap={2}>
          <AlertIcon w={4} h={4} fill="red.500" />
          <Text textStyle="body1Medium">
            Stagnant 4 days in {jobCandidate.workflowStage.name} stage
          </Text>
        </Flex>

        <Flex alignItems="center" gap={2}>
          <Text textStyle="body2Regular">
            Take action, prevent candidate fallout{' '}
          </Text>

          <Button hierarchy="link" size="small" onClick={reopenTask}>
            My nxmoov
          </Button>
        </Flex>
      </Stack>
    </>
  );
}

export default ViewJobCandidateDetailsTaskRequireAttention;
