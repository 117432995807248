import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  JobCandidateAssessmentFields,
  JobCandidateAssessmentMessage,
  JobCandidateAssessmentPages
} from '../../../../../jobCandidateAssessmentsTypes';

import { useAddJobCandidateAssessmentFormValidationRules } from '../useAddJobCandidateAssessmentFormValidationRules';

import {
  AddJobCandidateAssessmentFormData,
  OnAddJobCandidateAssessmentForm
} from '../../AddJobCandidateAssessmentForm.types';

const defaultAddJobCandidateAssessmentValues: AddJobCandidateAssessmentFormData =
  {
    message: '' as JobCandidateAssessmentMessage,
    pages: [] as string[] as JobCandidateAssessmentPages
  };

interface AddJobCandidateAssessmentFormOptions {
  defaultValues?: Partial<AddJobCandidateAssessmentFormData>;
  onAddJobCandidateAssessment: OnAddJobCandidateAssessmentForm;
}

function useAddJobCandidateAssessmentForm({
  defaultValues = {},
  onAddJobCandidateAssessment
}: AddJobCandidateAssessmentFormOptions) {
  const {
    control,
    watch,
    errors,
    trigger,
    isLoading,
    errorMessage,
    resetForm,
    register,
    setValue,
    handleSubmitReactHookForm
  } = useReactHookForm<AddJobCandidateAssessmentFormData>({
    defaultValues: {
      ...defaultAddJobCandidateAssessmentValues,
      ...defaultValues
    }
  });

  const { messageRules } = useAddJobCandidateAssessmentFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      messageValidationError: (errors.message as ReactHookFormFieldError)
        ?.message
    },
    setAddJobCandidateAssessmentForm: setValue,
    triggerJobCandidateAssessmentForm: trigger,
    addJobCandidateAssessmentFormIsLoading: isLoading,
    addJobCandidateAssessmentFormErrorMessage: errorMessage,
    resetAddJobCandidateAssessmentForm: resetForm,
    registerFields: {
      registerMessage: register(
        JobCandidateAssessmentFields.MESSAGE,
        messageRules
      )
    },
    handleAddJobCandidateAssessment: handleSubmitReactHookForm({
      onSubmit: (data) => {
        onAddJobCandidateAssessment?.({
          message: data.message as JobCandidateAssessmentMessage,
          pages: data.pages as JobCandidateAssessmentPages
        });
      }
    })
  };
}

export default useAddJobCandidateAssessmentForm;
