import { DepartmentNanoId } from '../departments/departmentsTypes';
import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../jobs/jobsTypes';
import { SourcingRoutes } from './SourcingRoutes';

const basePath = (
  departmentNanoId: DepartmentNanoId,
  jobCandidateNanoId: JobCandidateNanoId,
  jobNanoId: JobNanoId
) => SourcingRoutes.viewPerson(departmentNanoId, jobCandidateNanoId, jobNanoId);

export class SourcingJobCandidateDetailsRoutes {
  static index(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId, jobNanoId)}`;
  }

  static edit(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId, jobNanoId)}/edit`;
  }

  static profile(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/profile`;
  }

  static tasks(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId, jobNanoId)}/tasks`;
  }

  static files(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId, jobNanoId)}/files`;
  }

  static assessment(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/assessment`;
  }

  static projects(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/projects`;
  }

  static references(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/references`;
  }

  static activities(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/activities`;
  }

  static scoreCards(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${basePath(
      departmentNanoId,
      jobCandidateNanoId,
      jobNanoId
    )}/score-cards`;
  }
}
