import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { useShareCandidatesProfileContext } from '../../../../../../context';
import { Button } from '../../../../../../helpers/Button';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { ButtonWithRef } from '../../../../../../helpers/ButtonWithRef';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { ChevronLeftIcon } from '../../../../../../icons/ChevronLeftIcon';
import { EmailIcon } from '../../../../../../icons/EmailIcon';
import { FolderIcon } from '../../../../../../icons/FolderIcon';
import { MessageIcon } from '../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../icons/PhoneIcon';
import { ShareIcon } from '../../../../../../icons/ShareIcon';
import { ViewJobCandidateModalPhoneSidebarHelper } from '../../../../components/modals/ViewJobCandidateModal/helpers/ViewJobCandidateModalPhoneSidebarHelper';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageHandleGoBack
} from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsAddComment } from '../ViewJobCandidateDetailsAddComment';
import { ViewJobCandidateDetailsComposeEmail } from '../ViewJobCandidateDetailsComposeEmail';
import { ViewJobCandidateDetailsHeaderMenuAddJob } from '../ViewJobCandidateDetailsHeaderMenuAddJob';
import { ViewJobCandidateDetailsPageOverlay } from '../ViewJobCandidateDetailsPageOverlay';
import startCase from 'lodash/startCase';
import { IsDisabled } from '../../../../../../types';
import { JobNanoId } from '../../../../jobsTypes';

interface ViewJobCandidateDetailsJobDetailsProps {
  isDisabled?: IsDisabled;
  jobCandidate: JobCandidateDetailsJobCandidate;
  handleGoBack: JobCandidateDetailsPageHandleGoBack;
  handleRouteToJob: (jobNanoId: JobNanoId) => void;
}

export default function ViewJobCandidateDetailsJobDetails({
  isDisabled,
  jobCandidate,
  handleGoBack,
  handleRouteToJob
}: ViewJobCandidateDetailsJobDetailsProps) {
  const { shareJobCandidate } = useShareCandidatesProfileContext();

  const {
    isOpen: isOpenPhonrCallActivity,
    onOpen: onOpenPhonrCallActivity,
    onToggle: onTogglePhonrCallActivity
  } = useDisclosure();
  return (
    <>
      <Flex pr={4} pb={4} gap={6} pos="relative" alignItems="center">
        {isDisabled && <ViewJobCandidateDetailsPageOverlay />}

        <NextLinkHelper href={handleGoBack().url} passHref>
          <ButtonWithRef
            as="a"
            h={8}
            size="medium"
            display="flex"
            color="gray.700"
            fontWeight="normal"
            hierarchy="unstyled"
            leftIcon={<ChevronLeftIcon w={5} h={5} />}
            onClick={() => handleGoBack().url}
          >
            Back to {startCase(handleGoBack().label)}
          </ButtonWithRef>
        </NextLinkHelper>
        <HStack
          ml="auto"
          spacing={4}
          flexWrap="wrap"
          alignItems="center"
          divider={<StackDivider />}
        >
          <ViewJobCandidateDetailsHeaderMenuAddJob
            jobCandidate={jobCandidate}
            handleRouteToJob={handleRouteToJob}
          />

          <Button
            h={8}
            size="medium"
            display="flex"
            color="gray.600"
            hierarchy="unstyled"
            leftIcon={<ShareIcon w={5} h={5} />}
            onClick={() => shareJobCandidate(jobCandidate)}
          >
            Share
          </Button>

          <Button
            h={8}
            size="medium"
            display="flex"
            color="gray.600"
            hierarchy="unstyled"
            leftIcon={<FolderIcon w={5} h={5} />}
          >
            Move
          </Button>
        </HStack>

        <Flex gap={3} alignItems="center">
          <ViewJobCandidateDetailsComposeEmail
            trigger={
              <Tooltip label="Email" textTransform="capitalize" hasArrow>
                <IconButton
                  h={6}
                  w={6}
                  minW={0}
                  minH={0}
                  value="email"
                  display="flex"
                  aria-label=""
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                  icon={<EmailIcon w={3.5} h={3.5} />}
                  hierarchy="tertiary"
                />
              </Tooltip>
            }
          />

          <ViewJobCandidateDetailsAddComment
            trigger={
              <Tooltip label="Comment" textTransform="capitalize" hasArrow>
                <IconButton
                  h={6}
                  w={6}
                  minW={0}
                  minH={0}
                  value="comment"
                  display="flex"
                  aria-label=""
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                  icon={<MessageIcon w={3.5} h={3.5} />}
                  hierarchy="tertiary"
                />
              </Tooltip>
            }
          />

          <HStack
            spacing={0}
            overflow="hidden"
            borderRadius="base"
            divider={<StackDivider borderColor="primary.100" />}
          >
            <Button
              h={7}
              px={4}
              size="extra-small"
              borderRadius="none"
              onClick={onOpenPhonrCallActivity}
              leftIcon={<PhoneIcon w={3} h={3} fill="white" />}
            >
              Call
            </Button>

            <IconButton
              h={7}
              display="flex"
              aria-label=""
              size="extra-small"
              alignItems="center"
              borderRadius="none"
              justifyContent="center"
              icon={<ChevronDownIcon w={3.5} h={3.5} fill="white" />}
            />
          </HStack>
        </Flex>
      </Flex>

      <Drawer
        size="md"
        isOpen={isOpenPhonrCallActivity}
        onClose={onTogglePhonrCallActivity}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerBody p={0}>
            {jobCandidate ? (
              <ViewJobCandidateModalPhoneSidebarHelper
                fullWidth
                withoutBorder
                jobCandidate={jobCandidate}
                toggleFollowUpEmail={console.log}
                isSidebarOpen={isOpenPhonrCallActivity}
                sidebarToggle={onTogglePhonrCallActivity}
                personalPhoneNumber={jobCandidate.phone}
                companyPhoneNumber={jobCandidate.companyPhone}
              />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
