import { Flex, HStack, Stack } from '@chakra-ui/react';
import { ButtonWithRef } from '../../../../../../../../helpers/ButtonWithRef';
import { Heading } from '../../../../../../../../helpers/Heading';
import { NextLinkHelper } from '../../../../../../../../helpers/NextLinkHelper';
import { Text } from '../../../../../../../../helpers/Text';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import { ViewJobCandidateDetailsRoutes } from '../../../../ViewJobCandidateDetailsRoutes';
import { ViewJobCandidateDetailsCandidate } from '../../../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { SourcingJobCandidateDetailsRoutes } from '../../../../../../../sourcing/SourcingJobCandidateDetailsRoutes';
import { HiringPortalSourcingJobCandidateDetailsRoutes } from '../../../../../../../hiringPortal/HiringPortalSourcingJobCandidateDetailsRoutes';
import { DepartmentNanoId } from '../../../../../../../departments/departmentsTypes';
import { JobNanoId } from '../../../../../../jobsTypes';

interface ViewJobCandidateDetailsProfileOverviewTasksProps {
  jobCandidate: ViewJobCandidateDetailsCandidate;
  isHiringPortalPage: boolean;
  prospect?: boolean;
  departmentNanoId?: DepartmentNanoId;
  jobNanoId: JobNanoId;
}

function ViewJobCandidateDetailsProfileOverviewTasks({
  jobCandidate,
  isHiringPortalPage,
  prospect,
  departmentNanoId,
  jobNanoId
}: ViewJobCandidateDetailsProfileOverviewTasksProps) {
  const hiringPortalSourcingRoutes =
    isHiringPortalPage && prospect
      ? HiringPortalSourcingJobCandidateDetailsRoutes
      : null;

  const hiringPortalRoutes = isHiringPortalPage
    ? HiringPortalJobCandidateDetailsRoutes
    : null;

  const prospectRoute =
    hiringPortalSourcingRoutes || SourcingJobCandidateDetailsRoutes;

  const route = hiringPortalRoutes || ViewJobCandidateDetailsRoutes;

  return (
    <Stack p={4} gap={3} flex={1} bg="white" borderRadius="base">
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Tasks
        </Heading>

        <NextLinkHelper
          href={
            prospect
              ? prospectRoute.tasks(
                  departmentNanoId as DepartmentNanoId,
                  jobCandidate.nanoId,
                  jobNanoId
                )
              : route.tasks(jobCandidate.job.nanoId, jobCandidate.nanoId)
          }
          passHref
        >
          <ButtonWithRef as="a" hierarchy="link" size="small" ml="auto">
            View all
          </ButtonWithRef>
        </NextLinkHelper>
      </Flex>

      <Stack spacing={2} pl={4}>
        <HStack>
          <CheckIcon w={4} h={4} fill="green.500" />

          <Text noOfLines={1} wordBreak="break-all">
            Recruiting Call
          </Text>
        </HStack>

        <HStack>
          <CheckIcon w={4} h={4} fill="green.500" />

          <Text noOfLines={1} wordBreak="break-all">
            Schedule Introduction Call
          </Text>
        </HStack>

        <HStack>
          <CheckIcon w={4} h={4} fill="green.500" />

          <Text noOfLines={1} wordBreak="break-all">
            Debrief Call
          </Text>
        </HStack>
      </Stack>
    </Stack>
  );
}

export default ViewJobCandidateDetailsProfileOverviewTasks;
