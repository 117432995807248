import { Flex, Spinner, Stack } from '@chakra-ui/react';
import { JobCandidateId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { useDeleteJobJobCandidate } from '../../../../../jobJobCandidates/hooks/useDeleteJobJobCandidate';
import { JobJobCandidatesCache } from '../../../../../jobJobCandidates/JobJobCandidatesCache';
import {
  JobJobCandidateId,
  JobJobCandidateJob
} from '../../../../../jobJobCandidates/jobJobCandidatesTypes';
import { Heading } from '../../../../../../helpers/Heading';
import { renderJobTitle } from '../../../../utils/renderJobTitle';
import { JobLocationHelper } from '../../../../helpers/JobLocationHelper';
import { DeleteIcon } from '../../../../../../icons/DeleteIcon';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { Fragment } from 'react';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { JobNanoId } from '../../../../jobsTypes';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';

interface ViewJobCandidateDetailsHeaderMenuJobsJobItemProps {
  jobJobCandidateId: JobJobCandidateId;
  jobJobCandidateJob: JobJobCandidateJob;
  jobCandidateId: JobCandidateId;
  onClose: () => void;
  jobRoute: (jobNanoId: JobNanoId) => string;
}

export default function ViewJobCandidateDetailsHeaderMenuJobsJobItem({
  jobJobCandidateId,
  jobJobCandidateJob,
  jobCandidateId,
  onClose,
  jobRoute
}: ViewJobCandidateDetailsHeaderMenuJobsJobItemProps) {
  const {
    deleteJobJobCandidate,
    deleteJobJobCandidateIsLoading,
    deleteJobJobCandidateErrorMessage
  } = useDeleteJobJobCandidate({
    jobJobCandidateId,
    cacheKeys: [JobJobCandidatesCache.jobCandidateListCacheKey(jobCandidateId)]
  });

  return (
    <Fragment>
      <AlertMessage message={deleteJobJobCandidateErrorMessage} />
      <Flex justifyContent="space-between" alignItems="start" gap={2}>
        <Stack spacing={0.5}>
          <NextLinkHelper href={jobRoute(jobJobCandidateJob.nanoId)} passHref>
            <Heading
              as="a"
              level="h2"
              fontSize="md"
              fontWeight="semibold"
              lineHeight={1.5}
              color="gray.900"
              cursor="pointer"
              _hover={{
                textDecoration: 'none'
              }}
              onClick={onClose}
            >
              {renderJobTitle(jobJobCandidateJob)}
            </Heading>
          </NextLinkHelper>

          <JobLocationHelper
            textStyle="body2Regular"
            job={jobJobCandidateJob}
          />
        </Stack>

        <IconButton
          hierarchy="ghost"
          size="small"
          icon={
            deleteJobJobCandidateIsLoading ? (
              <Spinner size="sm" />
            ) : (
              <DeleteIcon />
            )
          }
          mt={1}
          aria-label="Delete job"
          onClick={deleteJobJobCandidate}
        />
      </Flex>
    </Fragment>
  );
}
