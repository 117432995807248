import { Avatar, Flex, HStack } from '@chakra-ui/react';
import some from 'lodash/some';
import { useRouter } from 'next/router';
import { Text } from '../../../../../../helpers/Text';
import { CandidatesIcon } from '../../../../../../icons/CandidatesIcon';
import { CareerSiteIcon } from '../../../../../../icons/CareerSiteIcon';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { EditIcon } from '../../../../../../icons/EditIcon';
import { EyeIcon } from '../../../../../../icons/EyeIcon';
import { FilesIcon } from '../../../../../../icons/FilesIcon';
import { RecruitersIcon } from '../../../../../../icons/RecruitersIcon';
import { TaskIcon } from '../../../../../../icons/TaskIcon';
import {
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { HiringPortalSourcingJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalSourcingJobCandidateDetailsRoutes';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { SourcingJobCandidateDetailsRoutes } from '../../../../../sourcing/SourcingJobCandidateDetailsRoutes';
import { JobNanoId } from '../../../../jobsTypes';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { JobCandidateDetailsPageReferrer } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsRoutes } from '../../ViewJobCandidateDetailsRoutes';
import { ViewJobCandidateDetailsPageNavItem } from '../ViewJobCandidateDetailsPageNavItem';
import { ViewJobCandidateDetailsPageOverlay } from '../ViewJobCandidateDetailsPageOverlay';
import { IsDisabled } from '../../../../../../types';

interface ViewJobCandidateDetailsPageNavProps {
  prospect?: boolean;
  jobNanoId: JobNanoId;
  isBasePath?: boolean;
  isDisabled?: IsDisabled;
  isHiringPortalPath: boolean;
  jobCandidateNanoId: JobCandidateNanoId;
  departmentNanoId?: DepartmentNanoId;
}

interface EditRoute {
  edit: (
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer: JobCandidateDetailsPageReferrer
  ) => string;
}

export default function ViewJobCandidateDetailsPageNav({
  prospect,
  jobNanoId,
  isDisabled,
  isBasePath,
  isHiringPortalPath,
  jobCandidateNanoId,
  departmentNanoId = '' as DepartmentNanoId
}: ViewJobCandidateDetailsPageNavProps) {
  const router = useRouter();

  const isEditing = router.pathname.includes('edit');

  const hiringPortalSourcingRoutes =
    isHiringPortalPath && prospect
      ? HiringPortalSourcingJobCandidateDetailsRoutes
      : null;

  const hiringPortalRoutes = isHiringPortalPath
    ? HiringPortalJobCandidateDetailsRoutes
    : null;

  const prospectRoute =
    hiringPortalSourcingRoutes || SourcingJobCandidateDetailsRoutes;

  const route = hiringPortalRoutes || ViewJobCandidateDetailsRoutes;

  const { shareSettings } = useViewJobCandidateDetailsSubmissionContext();

  const assessmentFields = [
    CandidateSubmissionShareSettingsFields.AssessmentPreferences,
    CandidateSubmissionShareSettingsFields.AssessmentQualifications,
    CandidateSubmissionShareSettingsFields.AssessmentSkillsExpertise,
    CandidateSubmissionShareSettingsFields.AssessmentQuestions,
    CandidateSubmissionShareSettingsFields.AssessmentProjects,
    CandidateSubmissionShareSettingsFields.AssessmentReferences,
    CandidateSubmissionShareSettingsFields.AssessmentUpdatedResume
  ];

  const hasAsessmentField = some(shareSettings, (field) =>
    assessmentFields.includes(field)
  );

  const { referrer } = router.query as {
    referrer: JobCandidateDetailsPageReferrer | undefined;
  };

  return (
    <HStack
      h={16}
      py={3}
      top={14}
      zIndex={9}
      spacing={2}
      overflow="auto"
      pos="sticky"
      bg="gray.50"
      px={0}
    >
      {isDisabled && <ViewJobCandidateDetailsPageOverlay />}

      <ViewJobCandidateDetailsPageNavItem
        label="Profile"
        icon={RecruitersIcon}
        isActive={isBasePath || router.pathname.includes('profile')}
        url={`${
          prospect
            ? prospectRoute.profile(
                departmentNanoId,
                jobCandidateNanoId,
                jobNanoId
              )
            : route.profile(
                jobNanoId as JobNanoId,
                jobCandidateNanoId as JobCandidateNanoId,
                referrer as JobCandidateDetailsPageReferrer
              )
        }`}
      />

      {isEditing ? (
        <ViewJobCandidateDetailsPageNavItem
          icon={EditIcon}
          label="Edit"
          isActive={router.pathname.includes('edit')}
          url={`${
            prospect
              ? prospectRoute.edit(
                  departmentNanoId,
                  jobCandidateNanoId,
                  jobNanoId
                )
              : (route as unknown as EditRoute).edit(
                  jobNanoId as JobNanoId,
                  jobCandidateNanoId as JobCandidateNanoId,
                  referrer as JobCandidateDetailsPageReferrer
                )
          }`}
        />
      ) : null}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.Files as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsPageNavItem
          icon={FilesIcon}
          label="Files"
          isActive={router.pathname.includes('files')}
          url={`${
            prospect
              ? prospectRoute.files(
                  departmentNanoId,
                  jobCandidateNanoId,
                  jobNanoId
                )
              : route.files(
                  jobNanoId as JobNanoId,
                  jobCandidateNanoId as JobCandidateNanoId,
                  referrer as JobCandidateDetailsPageReferrer
                )
          }`}
        />
      )}

      {hasAsessmentField && (
        <ViewJobCandidateDetailsPageNavItem
          icon={EyeIcon}
          label="Assessment"
          isActive={router.pathname.includes('assessment')}
          url={`${
            prospect
              ? prospectRoute.assessment(
                  departmentNanoId,
                  jobCandidateNanoId,
                  jobNanoId
                )
              : route.assessment(
                  jobNanoId as JobNanoId,
                  jobCandidateNanoId as JobCandidateNanoId,
                  referrer as JobCandidateDetailsPageReferrer
                )
          }`}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.AssessmentProjects as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsPageNavItem
          icon={TaskIcon}
          label="Projects"
          isActive={router.pathname.includes('projects')}
          url={`${
            prospect
              ? prospectRoute.projects(
                  departmentNanoId,
                  jobCandidateNanoId,
                  jobNanoId
                )
              : route.projects(
                  jobNanoId as JobNanoId,
                  jobCandidateNanoId as JobCandidateNanoId,
                  referrer as JobCandidateDetailsPageReferrer
                )
          }`}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.AssessmentReferences as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsPageNavItem
          icon={CandidatesIcon}
          label="References"
          isActive={router.pathname.includes('references')}
          url={`${
            prospect
              ? prospectRoute.references(
                  departmentNanoId,
                  jobCandidateNanoId,
                  jobNanoId
                )
              : route.references(
                  jobNanoId as JobNanoId,
                  jobCandidateNanoId as JobCandidateNanoId,
                  referrer as JobCandidateDetailsPageReferrer
                )
          }`}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ScoreCards as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsPageNavItem
          icon={CareerSiteIcon}
          label="Score cards"
          isActive={router.pathname.includes('score-cards')}
          url={`${
            prospect
              ? prospectRoute.scoreCards(
                  departmentNanoId,
                  jobCandidateNanoId,
                  jobNanoId
                )
              : route.scoreCards(
                  jobNanoId as JobNanoId,
                  jobCandidateNanoId as JobCandidateNanoId,
                  referrer as JobCandidateDetailsPageReferrer
                )
          }`}
        />
      )}

      <Flex flex={1}>
        <Flex ml="auto" alignItems="center" gap={2}>
          <Text textStyle="body1Medium" color="gray.600">
            Owner
          </Text>

          <Flex
            px={2}
            py={1.5}
            gap={2}
            bg="white"
            cursor="pointer"
            alignItems="center"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="base"
          >
            <Avatar size="xs" src="/images/team-mate-2.png" />
            <Text textStyle="body1Medium" noOfLines={1}>
              Anna Smith
            </Text>

            <ChevronDownIcon w={4} h={4} />
          </Flex>
        </Flex>
      </Flex>
    </HStack>
  );
}
