import { Box, Fade, Flex, Spinner, Stack } from '@chakra-ui/react';
import find from 'lodash/find';
import compact from 'lodash/compact';
import truncate from 'lodash/truncate';
import some from 'lodash/some';
import { ChangeEvent, RefObject, useCallback, useState } from 'react';

import { AvatarHelper } from '../../../../../helpers/AvatarHelper';
import { CheckboxField } from '../../../../../helpers/forms/formFields/CheckboxField';
import { NextLinkHelper } from '../../../../../helpers/NextLinkHelper';
import { RelativeDateHelper } from '../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../helpers/Text';

import { HeartIcon } from '../../../../../icons/HeartIcon';
import { LocationIcon } from '../../../../../icons/LocationIcon';

import { renderLocation } from '../../../../common/utils/renderLocation';

import { JobCandidateLabelHelper } from '../../../../jobCandidates/helpers/JobCandidateLabelHelper';
import { renderJobCandidateName } from '../../../../jobCandidates/utils/renderJobCandidateName';

import { ViewJobCandidateMenu } from '../../../../jobs/pages/ViewJobCandidatesPage/helpers/ViewJobCandidateMenu';

import { LikedJobCandidateId } from '../../../../likedJobCandidates/likedJobCandidatesTypes';
import { UserId } from '../../../../users/usersTypes';
import { TalentPoolJobCandidate } from './TalentPoolCandidateListItem.types';
import {
  JobCandidateNanoId,
  JobCandidateSearchTypeLabels
} from '../../../../jobCandidates/jobCandidatesTypes';

import { useDeleteLikedJobCandidate } from '../../../../likedJobCandidates/hooks/useDeleteLikedJobCandidate';

import JobCandidateSharedRecipients from '../../../../jobs/pages/ViewJobCandidatesPage/components/ViewJobCandidateCard/components/JobCandidateSharedRecipients/JobCandidateSharedRecipients';
import { LikedJobCandidatesCache } from '../../../../likedJobCandidates/LikedJobCandidatesCache';
import { useShareCandidatesProfileContext } from '../../../../../context';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import {
  DepartmentId,
  DepartmentNanoId
} from '../../../../departments/departmentsTypes';
import { DateTimeType, DateUtils } from '../../../../../utils/DateUtils';
import { JobNanoId } from '../../../../jobs/jobsTypes';

interface TalentPoolCandidateListItemProps {
  jobCandidate: TalentPoolJobCandidate;
  isActive: boolean;
  department?: {
    nanoId: DepartmentNanoId;
    id: DepartmentId;
  };
  currentUser: {
    id: UserId;
  };
  jobCandidateRoute: (
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) => string;
  isDisabled?: boolean;
  withoutProfileShare?: boolean;
  isHiringPortal?: boolean;
  itemRef?: RefObject<HTMLDivElement>;
}

function TalentPoolCandidateListItem({
  jobCandidate,
  isActive,
  department,
  currentUser,
  jobCandidateRoute,
  isDisabled,
  withoutProfileShare,
  isHiringPortal,
  itemRef
}: TalentPoolCandidateListItemProps) {
  const likedJobCandidateId = find(
    jobCandidate.likedJobCandidates,
    (likedJobCandidate) => likedJobCandidate.userId === Number(currentUser.id)
  )?.id as LikedJobCandidateId;

  const {
    deleteLikedJobCandidate,
    deleteLikedJobCandidateIsLoading,
    deleteLikedJobCandidateErrorMessage
  } = useDeleteLikedJobCandidate({
    likedJobCandidateId,
    cacheKeys: [LikedJobCandidatesCache.userIndexCacheKey(currentUser.id)]
  });

  const { toggleJobCandidateCheck, checkedJobCandidates } =
    useShareCandidatesProfileContext();

  const [isHovered, setIsHovered] = useState(false);

  const location = renderLocation(jobCandidate);

  const handleCheckCandidate = useCallback<
    (e: ChangeEvent<HTMLInputElement>) => void
  >(
    (e) => {
      toggleJobCandidateCheck(jobCandidate, e);
    },
    [jobCandidate, toggleJobCandidateCheck]
  );

  const isCandidateChecked = some(
    checkedJobCandidates,
    (checkedJobCandidate) => checkedJobCandidate.id === jobCandidate.id
  );

  const dueDay = DateUtils.calendarDay(jobCandidate.dueDate) as DateTimeType;
  const newTaskDay = DateUtils.calendarDay(
    jobCandidate.newWorkflowStageTaskAt
  ) as DateTimeType;
  const today = DateUtils.startOfToday();
  const scheduledDay = DateUtils.calendarDay(
    jobCandidate.workflowStageTaskScheduledAt
  ) as DateTimeType;
  const dateDifference =
    today && newTaskDay ? today.diff(newTaskDay, 'days')?.toObject() : {};

  const safe =
    jobCandidate.workflowStageTaskScheduledAt &&
    (today.equals(scheduledDay) || today.equals(dueDay))
      ? 'primary.500'
      : undefined;

  const overdue =
    jobCandidate.workflowStageTaskScheduledAt && today > dueDay
      ? 'red.500'
      : undefined;

  const alert =
    jobCandidate.newWorkflowStageTaskAt &&
    today > newTaskDay &&
    (dateDifference?.days as number) >= 2
      ? '#EEA401'
      : '';

  const borderColor = safe || overdue || alert || 'primary.500';

  return (
    <Stack
      as="a"
      position="relative"
      spacing={0}
      direction="row"
      color="gray.900"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transitionDuration="slow"
      transitionProperty="background"
      ref={itemRef}
      bg={isActive ? 'primary.100' : 'white'}
      _hover={{ textDecoration: 'none', bg: 'primary.50' }}
      borderLeft="4px solid"
      borderColor={borderColor}
    >
      <Flex alignItems="flex-start" py={4} pl={4} as="label" cursor="pointer">
        <Flex pos="relative">
          <AvatarHelper
            image={jobCandidate.image}
            size="sm"
            opacity={isHovered || isCandidateChecked ? 0 : 1}
            transitionProperty="opacity"
            transitionDuration="slow"
          />

          <Flex
            h="100%"
            w="100%"
            pos="absolute"
            cursor="pointer"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            opacity={isHovered || isCandidateChecked ? 1 : 0}
            transitionProperty="opacity"
            transitionDuration="slow"
          >
            <CheckboxField
              id={jobCandidate.id}
              size="small"
              onChange={handleCheckCandidate}
              isChecked={isCandidateChecked}
            />
          </Flex>
        </Flex>
      </Flex>

      <NextLinkHelper
        href={
          isDisabled
            ? ''
            : jobCandidateRoute(
                (department?.nanoId || 'all') as DepartmentNanoId,
                jobCandidate.nanoId,
                jobCandidate.job?.nanoId
              )
        }
        passHref
      >
        <Stack
          as="a"
          py={4}
          pl={2}
          pr={4}
          flex={1}
          spacing={0.5}
          pos="relative"
          _hover={{ textDecoration: 'none' }}
        >
          <Flex alignItems="center" gap={2}>
            <Flex flexDir="row" alignItems="center" gap={2}>
              <Text
                color="gray.900"
                textStyle={
                  isHiringPortal &&
                  jobCandidate.searchTypeLabel ===
                    JobCandidateSearchTypeLabels.NEW
                    ? 'body1Medium'
                    : 'body1Regular'
                }
                noOfLines={1}
                flex={1}
              >
                {renderJobCandidateName(jobCandidate)}
              </Text>

              {deleteLikedJobCandidateIsLoading ? (
                <Spinner color="primary.500" size="xs" />
              ) : likedJobCandidateId ? (
                <HeartIcon
                  h={4}
                  fill="red.500"
                  cursor="pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteLikedJobCandidate(e);
                  }}
                />
              ) : null}

              {jobCandidate.searchTypeLabel ===
                JobCandidateSearchTypeLabels.APPLICANT ||
              jobCandidate.searchTypeLabel ===
                JobCandidateSearchTypeLabels.PROSPECT ? (
                <>
                  <Fade in={!isHovered}>
                    <JobCandidateLabelHelper
                      searchTypeLabel={jobCandidate.searchTypeLabel}
                      useIcon
                      fontSize="2xs"
                    />
                  </Fade>
                  {withoutProfileShare ? null : (
                    <JobCandidateSharedRecipients
                      isHovered={isHovered}
                      jobCandidate={jobCandidate}
                      job={jobCandidate.job}
                    />
                  )}
                </>
              ) : withoutProfileShare ? null : (
                <JobCandidateSharedRecipients
                  isHovered={isHovered}
                  jobCandidate={jobCandidate}
                  job={jobCandidate.job}
                />
              )}
            </Flex>

            {jobCandidate.searchTypeLabel ===
              JobCandidateSearchTypeLabels.APPLICANT ||
            jobCandidate.searchTypeLabel ===
              JobCandidateSearchTypeLabels.PROSPECT ? null : (
              <Flex ml="auto" alignItems="center">
                <Fade in={!isHovered}>
                  <JobCandidateLabelHelper
                    searchTypeLabel={jobCandidate.searchTypeLabel}
                    useIcon
                    fontSize="2xs"
                  />
                </Fade>
              </Flex>
            )}
          </Flex>

          <Text
            color={
              isHiringPortal &&
              jobCandidate.searchTypeLabel === JobCandidateSearchTypeLabels.NEW
                ? 'primary.500'
                : 'gray.600'
            }
            textStyle={
              isHiringPortal &&
              jobCandidate.searchTypeLabel === JobCandidateSearchTypeLabels.NEW
                ? 'body2Medium'
                : 'body2Regular'
            }
            noOfLines={1}
          >
            {compact([
              truncate(jobCandidate.jobTitle, { length: 23, omission: '...' }),
              jobCandidate.company?.name
            ]).join(' @ ')}
          </Text>

          <Flex>
            {location ? (
              <Flex alignItems="center" gap={1} flex={1}>
                <LocationIcon fill="gray.500" w={4} h={4} />
                <Text color="gray.700" textStyle="body2Regular" noOfLines={1}>
                  {location}
                </Text>
              </Flex>
            ) : null}

            <Text textStyle="body2Regular" color="gray.600" ml="auto">
              <RelativeDateHelper date={jobCandidate.createdAt} />
            </Text>
          </Flex>

          <AlertMessage message={deleteLikedJobCandidateErrorMessage} />
        </Stack>
      </NextLinkHelper>

      <Box position="absolute" right={4} top={1}>
        <Fade in={isHovered}>
          <ViewJobCandidateMenu
            isCandidateLiked={!!likedJobCandidateId}
            jobCandidateId={jobCandidate.id}
            userId={currentUser.id}
            jobCandidate={jobCandidate}
            hasAddToJob
          />
        </Fade>
      </Box>
    </Stack>
  );
}

export default TalentPoolCandidateListItem;
