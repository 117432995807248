import { Box, Flex, Grid, HStack, Tooltip } from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { Text } from '../../../../../../../../helpers/Text';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { ThumbDownIcon } from '../../../../../../../../icons/ThumbDownIcon';
import { useViewJobCandidateDetailsStageTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';
import { ViewJobCandidateDetailsTaskSubActionsDropdown } from '../ViewJobCandidateDetailsTaskSubActionsDropdown';

function ViewJobCandidateDetailsEmptyTaskHover() {
  const {
    skipTask,
    taskStatus,
    cancelTask,
    disqualifyCandidate,
    openSubActionsDropdown,
    closeSubActionsDropdown,
    onOpenDisqualificationPopup
  } = useViewJobCandidateDetailsStageTasksWidgetContext();

  return (
    <Grid
      gap={3}
      flex={1}
      gridTemplateColumns="max-content 1fr"
      alignItems="center"
      justifyContent="center"
    >
      <Flex gap={3}>
        <Box display="block">
          <Text
            noOfLines={1}
            wordBreak="break-all"
            textStyle="body2Medium"
            color={taskStatus.color}
          >
            {startCase(taskStatus.label)}
          </Text>
        </Box>

        <HStack flexGrow={0} spacing={1}>
          <ClockIcon w={3} h={3} />
          <Text textStyle="body2Regular">3 days ago</Text>
        </HStack>
      </Flex>

      <Flex gap={2} justifyContent="flex-end">
        <Flex>
          <Tooltip label="Discontinue" textTransform="capitalize" hasArrow>
            <IconButton
              h={6}
              w={6}
              minW={0}
              minH={0}
              display="flex"
              aria-label=""
              hierarchy="unstyled"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              onClick={onOpenDisqualificationPopup}
              icon={<ThumbDownIcon w={2.5} h={2.5} color="red.500" />}
              border="1px solid transparent"
              bg="red.100"
              _hover={{
                borderColor: 'red.500'
              }}
            />
          </Tooltip>
        </Flex>

        {taskStatus.label !== 'completed' && taskStatus.label !== 'new-task' && (
          <Flex>
            <ViewJobCandidateDetailsTaskSubActionsDropdown
              skipTask={skipTask}
              cancelTask={cancelTask}
              openDropdown={openSubActionsDropdown}
              closeDropdown={closeSubActionsDropdown}
              disqualifyCandidate={disqualifyCandidate}
              actionSelected={taskStatus.label === 'in-progress'}
            />
          </Flex>
        )}
      </Flex>
    </Grid>
  );
}

export default ViewJobCandidateDetailsEmptyTaskHover;
