import { DepartmentNanoId } from '../departments/departmentsTypes';
import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../jobs/jobsTypes';
import { TalentPoolNanoId } from '../talentPools/talentPoolsTypes';

const baseSourcingPath = '/sourcing';
const baseProspectsPath = `${baseSourcingPath}/prospects`;
const baseTalentPoolPath = `${baseSourcingPath}/talent-pool`;
const baseRelationshipsPath = `${baseSourcingPath}/relationships`;

export class SourcingRoutes {
  static index() {
    return baseSourcingPath;
  }

  static prospects() {
    return baseProspectsPath;
  }

  static addProspect() {
    return `${baseProspectsPath}/add`;
  }

  static addProspectTalents(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/add/${poolNanoId}/talents`;
  }

  static addProspectIntake(poolNanoId: TalentPoolNanoId) {
    return `${baseProspectsPath}/add/${poolNanoId}/intake`;
  }

  static recruiters() {
    return `${baseProspectsPath}/recruiters`;
  }

  static viewPool(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/${poolNanoId}`;
  }

  static viewProspectDetails(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/${poolNanoId}/details`;
  }

  static viewIndex() {
    return `${baseProspectsPath}/view`;
  }

  static viewDepartment(departmentNanoId: DepartmentNanoId) {
    return `${baseProspectsPath}/view/${departmentNanoId}`;
  }

  static viewPerson(
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${baseProspectsPath}/view/${departmentNanoId}/${jobNanoId}/${personNanoId}`;
  }

  static viewJobDetails(
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) {
    return `${baseProspectsPath}/view/${departmentNanoId}/${jobNanoId}/${personNanoId}/job-details`;
  }

  static viewPersonDetails(
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId,
    detail: string
  ) {
    return `${baseProspectsPath}/view/${departmentNanoId}/${jobNanoId}/${personNanoId}/${detail}`;
  }

  static editProspectDescription(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/edit/${poolNanoId}/description`;
  }

  static editProspectPreferences(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/edit/${poolNanoId}/preferences`;
  }

  static editProspectTeam(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/edit/${poolNanoId}/team`;
  }

  static editProspectSkills(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/edit/${poolNanoId}/skills`;
  }

  // TALENT POOL

  static talentPool() {
    return `${baseTalentPoolPath}`;
  }

  static viewTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseTalentPoolPath}/${poolNanoId}`;
  }

  static newTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseTalentPoolPath}/${poolNanoId}/new`;
  }

  static requestProfiles(poolNanoId: TalentPoolNanoId, jobNanoId?: JobNanoId) {
    return `${baseTalentPoolPath}/${poolNanoId}/request?jobNanoId=${jobNanoId}`;
  }

  // RELATIONSHIPS

  static relationships() {
    return `${baseRelationshipsPath}/hires`;
  }

  static hires() {
    return `${baseRelationshipsPath}/hires`;
  }

  static coWorkers() {
    return `${baseRelationshipsPath}/co-workers`;
  }

  static referrals() {
    return `${baseRelationshipsPath}/referrals`;
  }
}
