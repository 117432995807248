import { Flex } from '@chakra-ui/react';
import { RefObject } from 'react';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../jobsTypes';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsBio } from '../ViewJobCandidateDetailsBio';
import { ViewJobCandidateDetailsCandidateHeaderMenu } from '../ViewJobCandidateDetailsCandidateHeaderMenu';
import { ViewJobCandidateDetailsPageOverlay } from '../ViewJobCandidateDetailsPageOverlay';
import { ViewJobCandidateDetailsStageTasksManager } from '../ViewJobCandidateDetailsStageTasksManager';
import { IsDisabled } from '../../../../../../types';

interface ViewJobCandidateDetailsHeaderProps {
  isBasePath?: boolean;
  isDisabled?: IsDisabled;
  requiresCredit: boolean;
  isProspectPath?: boolean;
  isHiringPortalPath?: boolean;
  departmentNanoId?: DepartmentNanoId;
  jobCandidateNanoId: JobCandidateNanoId;
  jobCandidate: JobCandidateDetailsJobCandidate;
  isBioOutsideViewport?: RefObject<HTMLDivElement>;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
}

function ViewJobCandidateDetailsHeader({
  job,
  isBasePath,
  isDisabled,
  jobCandidate,
  isProspectPath,
  requiresCredit,
  departmentNanoId,
  jobCandidateNanoId,
  isHiringPortalPath,
  isBioOutsideViewport
}: ViewJobCandidateDetailsHeaderProps) {
  return (
    <Flex
      p={4}
      gap={4}
      pos="relative"
      flexDir={{ base: 'column', xl: 'row' }}
      justifyContent="space-between"
      bg="white"
    >
      {isDisabled && <ViewJobCandidateDetailsPageOverlay />}

      <ViewJobCandidateDetailsBio
        requiresCredit={requiresCredit}
        jobCandidate={jobCandidate}
        isBioOutsideViewport={isBioOutsideViewport}
        job={job}
        isHiringPortalPath={isHiringPortalPath}
        isProspectPath={isProspectPath}
        isBasePath={isBasePath}
        departmentNanoId={departmentNanoId}
      />

      <Flex
        flexDir="column"
        __css={
          isDisabled
            ? {
                '*': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none'
                }
              }
            : undefined
        }
      >
        <ViewJobCandidateDetailsCandidateHeaderMenu
          jobNanoId={job.nanoId}
          jobCandidateNanoId={jobCandidateNanoId}
          isHiringPortalPath={isHiringPortalPath}
          isProspectPath={isProspectPath}
          departmentNanoId={departmentNanoId}
        />

        <ViewJobCandidateDetailsStageTasksManager
          jobCandidateNanoId={jobCandidateNanoId}
          requiresCredit={requiresCredit}
          jobCandidate={jobCandidate}
          jobCandidateDetails={jobCandidate}
          isHiringPortalPath={isHiringPortalPath}
          job={job}
        />
      </Flex>
    </Flex>
  );
}

export default ViewJobCandidateDetailsHeader;
