import { ButtonGroup, Flex } from '@chakra-ui/react';
import size from 'lodash/size';
import { useLeftSidebarContext } from '../../../../../../../../context';
import { Button } from '../../../../../../../../helpers/Button';
import { ChevronLeftIcon } from '../../../../../../../../icons/ChevronLeftIcon';
import { IsLoading } from '../../../../../../../../types';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../contexts/ViewJobCandidateDetailsSendAssessmentContext';

interface ViewJobCandidateDetailsAssessmentNavProps {
  isLoading: IsLoading;
}

export default function ViewJobCandidateDetailsAssessmentNav({
  isLoading
}: ViewJobCandidateDetailsAssessmentNavProps) {
  const { isDocked } = useLeftSidebarContext();

  const {
    isLastStep,
    isFirstStep,
    goToPrevStep,
    goToNextStep,
    assessmentFields,
    sendEmailAssessment,
    closeSendEmailAssessment
  } = useViewJobCandidateDetailsSendAssessmentContext();

  return (
    <Flex
      h={16}
      py={3}
      gap={6}
      left={0}
      w="full"
      bottom={0}
      bg="white"
      pos="fixed"
      zIndex={99}
      borderTop="1px solid"
      borderTopColor="gray.200"
      transitionDuration="fast"
      justifyContent="space-between"
      pl={{ lg: isDocked ? 20 : 60 }}
    >
      <Flex flex={1} pl={{ base: 4, xl: 12 }}>
        <Button
          px={8}
          size="medium"
          hierarchy="secondary"
          isDisabled={isLoading}
          onClick={closeSendEmailAssessment}
        >
          Cancel
        </Button>

        <ButtonGroup w="400px" ml="auto" justifyContent="space-between" px={6}>
          <Button
            size="medium"
            color="gray.800"
            hierarchy="link"
            isDisabled={isFirstStep || isLoading}
            leftIcon={<ChevronLeftIcon />}
            onClick={goToPrevStep}
          >
            Back
          </Button>

          {isLastStep ? (
            <Button
              size="medium"
              px={8}
              onClick={sendEmailAssessment}
              isLoading={isLoading}
            >
              Send
            </Button>
          ) : (
            <Button
              px={8}
              size="medium"
              onClick={goToNextStep}
              isDisabled={size(assessmentFields) < 2 || isLoading}
            >
              Continue
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}
