import { Flex } from '@chakra-ui/react';
import { useViewJobCandidateDetailsStageTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';
import { ViewJobCandidateDetailsDisqualificationDetails } from '../ViewJobCandidateDetailsDisqualificationDetails';
import { ViewJobCandidateDetailsEmptyTaskHover } from '../ViewJobCandidateDetailsEmptyTaskHover';
import { ViewJobCandidateDetailsTaskTools } from '../ViewJobCandidateDetailsTaskTools';
import { ViewJobCandidateDetailsTasksDropdown } from '../ViewJobCandidateDetailsTasksDropdown';

export default function ViewJobCandidateDetailsTasksSection() {
  const { stageTasks, taskStatus, isCandidateDisqualified } =
    useViewJobCandidateDetailsStageTasksWidgetContext();

  return (
    <Flex p={3} w="full" gap={2} flexDir="column">
      {isCandidateDisqualified ? (
        <ViewJobCandidateDetailsDisqualificationDetails />
      ) : (
        <>
          <Flex
            h={8}
            w="full"
            pl={2.5}
            pr={1.5}
            bg="white"
            color="gray.700"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="base"
            fontWeight="normal"
          >
            <ViewJobCandidateDetailsTasksDropdown stageTasks={stageTasks} />
          </Flex>

          <Flex h={6} alignItems="center" position="relative">
            <Flex
              w="100%"
              h="100%"
              gap={2}
              flex={1}
              position="absolute"
              transitionDuration="slow"
            >
              {taskStatus.label === 'start-task' ? (
                <ViewJobCandidateDetailsTaskTools />
              ) : (
                <ViewJobCandidateDetailsEmptyTaskHover />
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}
