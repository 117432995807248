const messageRules = {
  required: 'Message is required'
};

function useAddJobCandidateAssessmentFormValidationRules() {
  return {
    messageRules
  };
}

export default useAddJobCandidateAssessmentFormValidationRules;
