import { ButtonGroup } from '@chakra-ui/button';
import { Flex, Stack } from '@chakra-ui/layout';
import includes from 'lodash/includes';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { JobId } from '../../../jobs/jobsTypes';

import { JobCandidateId } from '../../../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../../../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import { SkillId, SkillName } from '../../../skills/skillsTypes';

import {
  FetchSkillBadgesCacheKey,
  SkillBadgeContactId,
  SkillBadgeExperienceType,
  skillBadgeExperienceTypes,
  SkillBadgeFields,
  SkillBadgeId,
  SkillBadgeJobCandidateId,
  SkillBadgeJobId,
  SkillBadgeJobResumeId,
  SkillBadgeJobResumeRecruiterId,
  SkillBadgeNanoId,
  SkillBadgeProfileId,
  SkillBadgeRequirements,
  skillBadgeRequirements,
  SkillBadgeRequirementsTypes,
  SkillBadgeSkillId,
  SkillBadgeSkillLicenseId,
  SkillBadgeTalentPoolId,
  SkillBadgeUserId
} from '../../skillBadgesTypes';

import {
  FetchSkillLicensesEnabled,
  SkillLicenseName,
  SkillLicenseNanoId,
  SkillLicenseUserId
} from '../../../skillLicenses/skillLicensesTypes';

import { TalentPoolId } from '../../../talentPools/talentPoolsTypes';

import { UserId } from '../../../users/usersTypes';

import {
  fetchSkillLicensesQuery,
  FetchSkillLicensesResponse
} from '../../../skillLicenses/queries/fetchSkillLicenses.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useCreateSkillLicense } from '../../../skillLicenses/hooks/useCreateSkillLicense';
import { usePaginatedSkillLicenses } from '../../../skillLicenses/hooks/usePaginatedSkillLicenses';
import { useCreateSkillBadge } from '../../hooks/useCreateSkillBadge';
import { useUpdateSkillBadge } from '../../hooks/useUpdateSkillBadge';
import { useAddEditSkillBadgeForm } from './hooks/useAddEditSkillBadgeForm';

import { SkillLicensesCache } from '../../../skillLicenses/SkillLicensesCache';

import { PlusIcon } from '../../../../icons/PlusIcon';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { ButtonHelper } from '../../../../helpers/buttons/ButtonHelper';
import { PureButtonHelper } from '../../../../helpers/buttons/PureButtonHelper';
import { RadioGroupField } from '../../../../helpers/forms/formFields/RadioGroupField';
import {
  SelectField,
  SelectFieldOnInputChange
} from '../../../../helpers/forms/formFields/SelectField';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';
import { Text } from '../../../../helpers/Text';

import { generateNanoId } from '../../../../utils/generateNanoId';

import { experienceOptions, ratingOptions } from './AddEditSkillBadgeForm.data';

import { renderSkillBadgeExperienceType } from '../../utils/renderSkillBadgeExperienceType';
import { renderSkillBadgeRequirements } from '../../utils/renderSkillBadgeRequirements';

import { ContactId } from '../../../contacts/contactsTypes';
import { AddEditSkillBadgeFormSkillBadge } from './AddEditSkillBadgeForm.types';

interface AddEditSkillBadgeFormDefaultProps {
  jobId?: JobId;
  jobCandidateId?: JobCandidateId;
  jobResumeId?: JobResumeId;
  jobResumeRecruiterId?: JobResumeRecruiterId;
  talentPoolId?: TalentPoolId;
  profileId?: UserId;
  contactId?: ContactId;
  skillBadgesCacheKey: FetchSkillBadgesCacheKey;
  onAdd: () => void;
  onDiscard: () => void;
  isIntakeForm?: boolean;
}

interface AddEditSkillBadgeFormWithSkillBadgeProps {
  skillBadge?: AddEditSkillBadgeFormSkillBadge;
  skill?: never;
}

interface AddEditSkillBadgeFormWithSkillProps {
  skillBadge?: never;
  skill?: {
    id: SkillId;
    name: SkillName;
  };
}

type AddEditSkillBadgeFormProps = AddEditSkillBadgeFormDefaultProps &
  (
    | AddEditSkillBadgeFormWithSkillBadgeProps
    | AddEditSkillBadgeFormWithSkillProps
  );

function AddEditSkillBadgeForm({
  jobId,
  jobCandidateId,
  jobResumeId,
  jobResumeRecruiterId,
  talentPoolId,
  profileId,
  contactId,
  skillBadgesCacheKey,
  skillBadge,
  skill,
  onAdd,
  onDiscard,
  isIntakeForm
}: AddEditSkillBadgeFormProps) {
  const currentUser = useCurrentUser();

  const [currentSkillBadgeId, setCurrentSkillBadgeId] =
    useState<SkillBadgeId | null>(skillBadge?.id || null);

  const {
    createSkillBadgeIsLoading,
    createSkillBadgeErrorMessage,
    createSkillBadge
  } = useCreateSkillBadge({
    cacheKeys: [skillBadgesCacheKey]
  });

  const {
    updateSkillBadgeIsLoading,
    updateSkillBadgeErrorMessage,
    updateSkillBadge
  } = useUpdateSkillBadge({
    skillBadgeNanoId: skillBadge?.nanoId || ('0' as SkillBadgeNanoId), // Hack, need to remove it
    cacheKeys: [skillBadgesCacheKey]
  });

  const { skillLicenses, skillLicensesErrorMessage, skillLicensesIsFetched } =
    usePaginatedSkillLicenses<FetchSkillLicensesResponse>({
      query: fetchSkillLicensesQuery,
      cacheKey: SkillLicensesCache.indexCacheKey(),
      enabled: !isIntakeForm as FetchSkillLicensesEnabled
    });

  const {
    createSkillLicense,
    createSkillLicenseErrorMessage,
    createSkillLicenseIsLoading
  } = useCreateSkillLicense({
    cacheKeys: [SkillLicensesCache.indexCacheKey()]
  });

  const {
    control,
    watch,
    validationErrors,
    addEditSkillBadgeFormIsLoading,
    addEditSkillBadgeFormErrorMessage,
    handleAddEditSkillBadgeForm,
    setAddEditSkillBadgeFormValue
  } = useAddEditSkillBadgeForm({
    ...(skillBadge
      ? {
          defaultValues: {
            rating: skillBadge.rating,
            experience: skillBadge.experience,
            certified: skillBadge.certified,
            experienceType: skillBadge.experienceType,
            requirements: skillBadge.requirements,
            skillLicenseId: skillBadge.skillLicenseId
          }
        }
      : {}),
    onAddEditSkillBadgeForm: async (data) => {
      if (skill?.id) {
        await createSkillBadge({
          ...data,
          skillId: skill.id as SkillBadgeSkillId,
          jobId: jobId as SkillBadgeJobId,
          jobCandidateId: jobCandidateId as SkillBadgeJobCandidateId,
          jobResumeId: jobResumeId as SkillBadgeJobResumeId,
          jobResumeRecruiterId:
            jobResumeRecruiterId as SkillBadgeJobResumeRecruiterId,
          talentPoolId: talentPoolId as SkillBadgeTalentPoolId,
          profileId: profileId as SkillBadgeProfileId,
          contactId: contactId as SkillBadgeContactId,
          userId: currentUser.id as SkillBadgeUserId,
          nanoId: generateNanoId<SkillBadgeNanoId>(),
          requirements: (data.requirements ||
            SkillBadgeRequirementsTypes.NONE) as SkillBadgeRequirements
        });
      }

      if (skillBadge?.id) {
        await updateSkillBadge(data);
      }

      onAdd();
    },
    isIntakeForm
  });

  const handleFormSubmit = useCallback<() => void>(
    () => handleAddEditSkillBadgeForm(),
    [handleAddEditSkillBadgeForm]
  );

  const [newSkillLicenseName, setNewSkillLicenseName] =
    useState<SkillLicenseName>('' as SkillLicenseName);

  const handleNewSkillLicenseNameChange = useCallback<SelectFieldOnInputChange>(
    (updatedNewSkillLicenseName) =>
      setNewSkillLicenseName(updatedNewSkillLicenseName as SkillLicenseName),
    [setNewSkillLicenseName]
  );

  const handleNewSkillLicenseCreate = useCallback<() => void>(async () => {
    const skillLicense = await createSkillLicense({
      name: newSkillLicenseName,
      nanoId: generateNanoId<SkillLicenseNanoId>(),
      userId: currentUser.id as SkillLicenseUserId
    });

    if (skillLicense?.id) {
      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.SKILL_LICENSE_ID as 'skillLicenseId',
        skillLicense.id as SkillBadgeSkillLicenseId
      );
    }
  }, [
    newSkillLicenseName,
    currentUser.id,
    createSkillLicense,
    setAddEditSkillBadgeFormValue
  ]);

  const requirements = watch(SkillBadgeFields.REQUIREMENTS);
  const experienceType = watch(SkillBadgeFields.EXPERIENCE_TYPE);

  const handleEditSkillBadgeRequirements = useCallback(
    () =>
      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.REQUIREMENTS as 'requirements',
        '' as SkillBadgeRequirements
      ),
    [setAddEditSkillBadgeFormValue]
  );

  const handleEditSkillBadgeExperienceType = useCallback(
    () =>
      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.EXPERIENCE_TYPE as 'experienceType',
        '' as SkillBadgeExperienceType
      ),
    [setAddEditSkillBadgeFormValue]
  );

  useEffect(() => {
    if (skillBadge?.id && skillBadge?.id !== currentSkillBadgeId) {
      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.REQUIREMENTS as 'requirements',
        skillBadge.requirements
      );

      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.EXPERIENCE_TYPE as 'experienceType',
        skillBadge.experienceType
      );

      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.RATING as 'rating',
        skillBadge.rating
      );

      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.EXPERIENCE as 'experience',
        skillBadge.experience
      );

      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.CERTIFIED as 'certified',
        skillBadge?.certified
      );

      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.SKILL_LICENSE_ID as 'skillLicenseId',
        skillBadge?.skillLicenseId
      );

      setCurrentSkillBadgeId(skillBadge.id);
    }
  }, [
    currentSkillBadgeId,
    skillBadge?.id,
    skillBadge?.rating,
    skillBadge?.experience,
    skillBadge?.certified,
    skillBadge?.requirements,
    skillBadge?.experienceType,
    skillBadge?.skillLicenseId,
    setCurrentSkillBadgeId,
    setAddEditSkillBadgeFormValue
  ]);

  return (
    <Stack
      p={4}
      spacing={4}
      flexDirection="column"
      bg="gray.50"
      borderRadius={4}
      border="1px solid"
      borderColor="gray.200"
    >
      <Text textStyle="body1Medium">
        {skill?.name || skillBadge?.skill?.name}
        {isIntakeForm ? (
          <>
            {requirements ? (
              <ButtonHelper
                hierarchy="link"
                ml={2}
                size="small"
                onClick={handleEditSkillBadgeRequirements}
              >
                {renderSkillBadgeRequirements(requirements)} &#8595;
              </ButtonHelper>
            ) : null}
          </>
        ) : (
          <>
            {experienceType ? (
              <ButtonHelper
                hierarchy="link"
                ml={2}
                size="small"
                onClick={handleEditSkillBadgeExperienceType}
              >
                {renderSkillBadgeExperienceType(experienceType)} &#8595;
              </ButtonHelper>
            ) : null}
          </>
        )}
      </Text>

      <Flex gap={4} alignItems="flex-start" flexWrap="wrap">
        {!requirements && isIntakeForm ? (
          <Flex alignItems="center" flex={1}>
            <RadioGroupField
              control={control}
              name={SkillBadgeFields.REQUIREMENTS}
              options={skillBadgeRequirements.map((requirement) => ({
                value: requirement,
                label: renderSkillBadgeRequirements(requirement)
              }))}
              errorMessage={validationErrors.requirementsValidationError}
            />
          </Flex>
        ) : !experienceType && !isIntakeForm ? (
          <Flex alignItems="center" flex={1}>
            <RadioGroupField
              control={control}
              name={SkillBadgeFields.EXPERIENCE_TYPE}
              options={skillBadgeExperienceTypes.map((experience) => ({
                value: experience,
                label: renderSkillBadgeExperienceType(experience)
              }))}
              errorMessage={validationErrors.experienceTypeValidationError}
            />
          </Flex>
        ) : (
          <Fragment>
            <Flex alignItems="center" flex={1}>
              <SelectField
                control={control}
                placeholder="Select rating"
                name={SkillBadgeFields.RATING}
                options={ratingOptions}
                errorMessage={validationErrors.ratingValidationError}
                autoFocus={true}
              />
            </Flex>

            <Flex alignItems="center" flex={1}>
              <SelectField
                control={control}
                placeholder="Experience"
                name={SkillBadgeFields.EXPERIENCE}
                options={experienceOptions}
                errorMessage={validationErrors.experienceValidationError}
              />
            </Flex>

            {isIntakeForm ? null : (
              <Flex flex={1} alignItems="center" h={10} w="100%" minW="200px">
                <AlertMessage
                  message={
                    skillLicensesErrorMessage || createSkillLicenseErrorMessage
                  }
                />
                <LoadingSkeleton loaded={skillLicensesIsFetched}>
                  <SelectField
                    control={control}
                    placeholder={
                      <Flex alignItems="center" gap={1}>
                        <PlusIcon w={5} h={5} ml={-1} />
                        <Text isTruncated>License / Certification</Text>
                      </Flex>
                    }
                    placeholderColor="primary.500"
                    withAddNewButton={
                      newSkillLicenseName !== '' &&
                      !includes(
                        skillLicenses.map((skillLicense) => skillLicense.name),
                        newSkillLicenseName
                      )
                    }
                    addNewButtonLabel={`Add ${newSkillLicenseName}`}
                    addNewButtonDisabled={createSkillLicenseIsLoading}
                    onInputChange={handleNewSkillLicenseNameChange}
                    addNewButtonAction={handleNewSkillLicenseCreate}
                    name={SkillBadgeFields.SKILL_LICENSE_ID}
                    options={skillLicenses.map((skillLicense) => ({
                      value: skillLicense.id,
                      label: skillLicense.name
                    }))}
                    defaultValue={
                      skillBadge?.skillLicenseId && skillBadge?.skillLicense
                        ? {
                            value: skillBadge.skillLicenseId,
                            label: skillBadge.skillLicense.name
                          }
                        : undefined
                    }
                    errorMessage={
                      validationErrors.skillLicenseIdValidationError
                    }
                  />
                </LoadingSkeleton>
              </Flex>
            )}
          </Fragment>
        )}
      </Flex>

      <Flex>
        <ButtonGroup ml="auto" gap={4}>
          <PureButtonHelper
            size="medium"
            hierarchy="unstyled"
            color="red.900"
            i18nText="Discard"
            onClick={onDiscard}
          />
          <PureButtonHelper
            size="medium"
            i18nText={skillBadge ? 'Update' : 'Add'}
            isLoading={
              addEditSkillBadgeFormIsLoading ||
              createSkillBadgeIsLoading ||
              updateSkillBadgeIsLoading
            }
            onClick={handleFormSubmit}
          />
        </ButtonGroup>
      </Flex>

      <AlertMessage
        message={
          addEditSkillBadgeFormErrorMessage ||
          createSkillBadgeErrorMessage ||
          updateSkillBadgeErrorMessage
        }
      />
    </Stack>
  );
}

export default AddEditSkillBadgeForm;
