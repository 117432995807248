import {
  ButtonGroup,
  Flex,
  Stack,
  Box,
  HStack,
  StackDivider
} from '@chakra-ui/react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import last from 'lodash/last';
import size from 'lodash/size';

import { JobResumeRecruiterId } from '../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import { SkillId, SkillName } from '../../../skills/skillsTypes';

import {
  FetchSkillBadgesCacheKey,
  MayBeSkillBadgeEmploymentIds,
  SkillBadgeEmploymentIds,
  SkillBadgeExperience,
  SkillBadgeFields,
  SkillBadgeId,
  SkillBadgeJobResumeRecruiterId,
  SkillBadgeNanoId,
  SkillBadgeRequirements,
  SkillBadgeRequirementsTypes,
  SkillBadgeSkillId,
  SkillBadgeUserId
} from '../../skillBadgesTypes';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useCreateSkillBadge } from '../../hooks/useCreateSkillBadge';
import { useUpdateSkillBadge } from '../../hooks/useUpdateSkillBadge';
import { useAddEditSkillBadgeForm } from '../AddEditSkillBadgeForm/hooks/useAddEditSkillBadgeForm';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { PureButtonHelper } from '../../../../helpers/buttons/PureButtonHelper';
import { SelectField } from '../../../../helpers/forms/formFields/SelectField';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';
import { Text } from '../../../../helpers/Text';

import { generateNanoId } from '../../../../utils/generateNanoId';

import { ratingOptions } from '../AddEditSkillBadgeForm/AddEditSkillBadgeForm.data';

import { AddEditSkillBadgeFormSkillBadge } from '../AddEditSkillBadgeForm/AddEditSkillBadgeForm.types';
import SelectEmploymentsFormField from '../../../common/components/formFields/SelectEmploymentsFormField/SelectEmploymentsFormField';
import { usePaginatedEmploymentSkillBadges } from '../../../employmentSkillBadges/hooks/usePaginatedEmploymentSkillBadges';
import {
  fetchEmploymentSkillBadgesQuery,
  FetchEmploymentSkillBadgesResponse
} from '../../../employmentSkillBadges/queries/fetchEmploymentSkillBadges.query';
import { EmploymentSkillBadgesCache } from '../../../employmentSkillBadges/EmploymentSkillBadgesCache';
import {
  EmploymentSkillBadgeEmploymentId,
  EmploymentSkillBadgeFields,
  EmploymentSkillBadgeId,
  EmploymentSkillBadgeSkillBadgeId,
  EmploymentSkillBadgeUsageTypes,
  FetchEmploymentSkillBadgesEnabled,
  FetchEmploymentSkillBadgesFilters,
  FetchEmploymentSkillBadgesSort
} from '../../../employmentSkillBadges/employmentSkillBadgesTypes';
import { AddEditEmploymentSkillBadgeForm } from '../../../employmentSkillBadges/components/AddEditEmploymentSkillBadgeForm';
import { useDeleteSkillBadge } from '../../hooks/useDeleteSkillBadge';
import { useCreateEmploymentSkillBadge } from '../../../employmentSkillBadges/hooks/useCreateEmploymentSkillBadge';
import { EmploymentSkillBadgesBffRequests } from '../../../employmentSkillBadges/EmploymentSkillBadgesBffRequests';

interface AddEditSkillBadgeFormDefaultProps {
  jobResumeRecruiterId?: JobResumeRecruiterId;
  skillBadgesCacheKey: FetchSkillBadgesCacheKey;
  onAdd: () => void;
  onDiscard: () => void;
}

interface AddEditSkillBadgeFormWithSkillBadgeProps {
  skillBadge?: AddEditSkillBadgeFormSkillBadge;
  skill?: never;
}

interface AddEditSkillBadgeFormWithSkillProps {
  skillBadge?: never;
  skill?: {
    id: SkillId;
    name: SkillName;
  };
}

const enum ScreensEnum {
  WORK_HISTROY = 'WORK_HISTORY',
  RATING = 'RATING',
  EMPLOYMENT_DETAILS = 'EMPLOYMENT_DETAILS'
}

type AddEditSkillBadgeFormProps = AddEditSkillBadgeFormDefaultProps &
  (
    | AddEditSkillBadgeFormWithSkillBadgeProps
    | AddEditSkillBadgeFormWithSkillProps
  );

function AddEditApplicantSkillBadgeForm({
  jobResumeRecruiterId,
  skillBadgesCacheKey,
  skillBadge,
  skill,
  onAdd,
  onDiscard
}: AddEditSkillBadgeFormProps) {
  const currentUser = useCurrentUser();

  const [currentSkillBadgeId, setCurrentSkillBadgeId] =
    useState<SkillBadgeId | null>(skillBadge?.id || null);
  const [currentSkillBadgeNanoId, setCurrentSkillBadgeNanoId] =
    useState<SkillBadgeNanoId | null>(skillBadge?.nanoId || null);
  const [currentScreen, setCurrentScreen] = useState(ScreensEnum.RATING);
  const [
    currentEmploymentSkillBadgeIndex,
    setCurrentEmploymentSkillBadgeIndex
  ] = useState<number>(0);
  const [newEmploymentSkillBadges, setNewEmploymentSkillBadges] = useState<
    FetchEmploymentSkillBadgesResponse[]
  >([]);
  const [
    newEmploymentSkillBadgeIsFetched,
    setNewEmploymentSkillBadgeIsFetched
  ] = useState<boolean>(!!skillBadge?.id);

  const {
    createSkillBadgeIsLoading,
    createSkillBadgeErrorMessage,
    createSkillBadge
  } = useCreateSkillBadge({
    cacheKeys: [skillBadgesCacheKey]
  });

  const {
    updateSkillBadgeIsLoading,
    updateSkillBadgeErrorMessage,
    updateSkillBadge
  } = useUpdateSkillBadge({
    skillBadgeNanoId:
      skillBadge?.nanoId ||
      currentSkillBadgeNanoId ||
      ('0' as SkillBadgeNanoId), // Hack, need to remove it
    cacheKeys: [skillBadgesCacheKey]
  });

  const {
    deleteSkillBadge,
    deleteSkillBadgeIsLoading,
    deleteSkillBadgeErrorMessage
  } = useDeleteSkillBadge({
    skillBadgeNanoId:
      skillBadge?.nanoId ||
      currentSkillBadgeNanoId ||
      ('0' as SkillBadgeNanoId), // Hack, need to remove it
    cacheKeys: [skillBadgesCacheKey]
  });

  const {
    employmentSkillBadges,
    employmentSkillBadgesErrorMessage,
    fetchEmploymentSkillBadges
  } = usePaginatedEmploymentSkillBadges<FetchEmploymentSkillBadgesResponse>({
    query: fetchEmploymentSkillBadgesQuery,
    cacheKey: EmploymentSkillBadgesCache.indexCacheKey(),
    enabled: Boolean(skillBadge?.id) as FetchEmploymentSkillBadgesEnabled,
    initialFilters: {
      ...(skillBadge
        ? {
            [EmploymentSkillBadgeFields.SKILL_BADGE_ID]: {
              operator: 'eq',
              value: skillBadge.id
            }
          }
        : {})
    } as unknown as FetchEmploymentSkillBadgesFilters,
    initialSort: {
      'employments.startDate': {
        ascending: false
      }
    } as unknown as FetchEmploymentSkillBadgesSort
  });

  const {
    createEmploymentSkillBadge,
    createEmploymentSkillBadgeErrorMessage,
    createEmploymentSkillBadgeIsLoading
  } = useCreateEmploymentSkillBadge({
    cacheKeys: [
      EmploymentSkillBadgesCache.skillBadgeIndexCacheKey(
        (skillBadge?.id ||
          currentSkillBadgeId) as EmploymentSkillBadgeSkillBadgeId
      )
    ]
  });

  const {
    control,
    validationErrors,
    addEditSkillBadgeFormIsLoading,
    addEditSkillBadgeFormErrorMessage,
    handleAddEditSkillBadgeForm,
    setAddEditSkillBadgeFormValue
  } = useAddEditSkillBadgeForm({
    ...(skillBadge
      ? {
          defaultValues: {
            rating: skillBadge.rating,
            employmentIds: (employmentSkillBadges.map(
              (employmentSkillBadge) => employmentSkillBadge.employmentId
            ) || []) as unknown as MayBeSkillBadgeEmploymentIds
          }
        }
      : {}),
    onAddEditSkillBadgeForm: async (data) => {
      if (currentScreen === ScreensEnum.RATING) {
        const payload = { rating: data.rating };
        if (skill?.id && !currentSkillBadgeId) {
          const newSkillBadge = await createSkillBadge({
            ...payload,
            experience: (data.rating === 'no_experience'
              ? 'none'
              : '2yr') as SkillBadgeExperience,
            skillId: skill.id as SkillBadgeSkillId,
            jobResumeRecruiterId:
              jobResumeRecruiterId as SkillBadgeJobResumeRecruiterId,
            userId: currentUser.id as SkillBadgeUserId,
            nanoId: generateNanoId<SkillBadgeNanoId>(),
            requirements:
              SkillBadgeRequirementsTypes.NONE as SkillBadgeRequirements
          });

          if (newSkillBadge.id) {
            setCurrentSkillBadgeNanoId(newSkillBadge.nanoId);
            setCurrentSkillBadgeId(newSkillBadge.id);
            setCurrentScreen(ScreensEnum.WORK_HISTROY);
          }
        }
        if (skillBadge?.id || currentSkillBadgeId) {
          const updatePayload =
            data.rating === 'no_experience'
              ? {
                  rating: data.rating,
                  experience: 'none' as SkillBadgeExperience
                }
              : payload;
          await updateSkillBadge(updatePayload);
        }

        if (data.rating === 'no_experience') {
          onAdd();
        } else {
          setCurrentScreen(ScreensEnum.WORK_HISTROY);
        }
      } else {
        const employmentIds = data.employmentIds as SkillBadgeEmploymentIds;
        const currentEmploymentIds = employmentSkillBadges.map(
          (employmentSkillBadge) => employmentSkillBadge.employmentId
        );
        if (!size(employmentIds) && !currentEmploymentIds) {
          return;
        }

        const removedEmploymentSkillBadges = !size(employmentIds)
          ? []
          : filter(
              employmentSkillBadges,
              (employmentSkillBadge) =>
                !includes(employmentIds, employmentSkillBadge.employmentId)
            );
        const newEmploymentIds = !size(employmentIds)
          ? []
          : filter(
              employmentIds,
              (employmentId) => !includes(currentEmploymentIds, employmentId)
            );

        const requests1 = newEmploymentIds.map((employmentId) =>
          createEmploymentSkillBadge({
            employmentId: employmentId as EmploymentSkillBadgeEmploymentId,
            skillBadgeId:
              currentSkillBadgeId as EmploymentSkillBadgeSkillBadgeId
          })
        );

        const requests2 = removedEmploymentSkillBadges.map(
          (removedEmploymentSkillBadge) =>
            EmploymentSkillBadgesBffRequests.deleteEmploymentSkillBadge(
              removedEmploymentSkillBadge.id
            )
        );

        await Promise.all([...requests1, ...requests2]);

        setCurrentScreen(ScreensEnum.EMPLOYMENT_DETAILS);
        const newEmploymentSkillBadgesData = await fetchEmploymentSkillBadges({
          nextFilters: {
            [EmploymentSkillBadgeFields.SKILL_BADGE_ID]: {
              operator: 'eq',
              value: currentSkillBadgeId as SkillBadgeId
            }
          }
        });

        setNewEmploymentSkillBadges(newEmploymentSkillBadgesData.data);
        setNewEmploymentSkillBadgeIsFetched(true);
      }
    },
    isApplicantSkillBadgeForm: true,
    isWorkHistoryScreen: currentScreen === ScreensEnum.WORK_HISTROY
  });

  const handleFormSubmit = useCallback<() => void>(
    () => handleAddEditSkillBadgeForm(),
    [handleAddEditSkillBadgeForm]
  );

  const handleDiscard = useCallback(async () => {
    if (currentSkillBadgeNanoId || skillBadge?.nanoId) {
      await deleteSkillBadge({});
      onDiscard();
    } else {
      onDiscard();
    }
  }, [
    onDiscard,
    currentSkillBadgeNanoId,
    skillBadge?.nanoId,
    deleteSkillBadge
  ]);

  const handleNextEmploymentSkillBadge = useCallback(() => {
    setCurrentEmploymentSkillBadgeIndex(currentEmploymentSkillBadgeIndex + 1);
  }, [currentEmploymentSkillBadgeIndex]);

  const employmentSkillBadgesToUse = useMemo(
    () =>
      size(newEmploymentSkillBadges)
        ? newEmploymentSkillBadges
        : employmentSkillBadges || [],
    [newEmploymentSkillBadges, employmentSkillBadges]
  );

  const handleEmploymentSkillBadgeClick = useCallback<
    (
      employmentSkillBadge: FetchEmploymentSkillBadgesResponse,
      index: number
    ) => void
  >(
    (employmentSkillBadge, index) => {
      if (
        skillBadge?.id ||
        (employmentSkillBadge.usage &&
          employmentSkillBadge.usage !== EmploymentSkillBadgeUsageTypes.NONE)
      ) {
        setCurrentEmploymentSkillBadgeIndex(index);
        setCurrentScreen(ScreensEnum.EMPLOYMENT_DETAILS);
      } else {
        return;
      }
    },
    [skillBadge]
  );

  useEffect(() => {
    if (skillBadge?.id && skillBadge?.id !== currentSkillBadgeId) {
      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.RATING as 'rating',
        skillBadge.rating
      );

      setCurrentSkillBadgeId(skillBadge.id);
    }

    if (skillBadge?.id && skillBadge?.id === currentSkillBadgeId) {
      setAddEditSkillBadgeFormValue(
        SkillBadgeFields.EMPLOYMENT_IDS as 'employmentIds',
        (employmentSkillBadges.map(
          (employmentSkillBadge) => employmentSkillBadge.employmentId
        ) || []) as unknown as MayBeSkillBadgeEmploymentIds
      );
    }
  }, [
    currentSkillBadgeId,
    skillBadge?.id,
    skillBadge?.rating,
    setCurrentSkillBadgeId,
    setAddEditSkillBadgeFormValue,
    employmentSkillBadges
  ]);

  return (
    <Stack
      p={4}
      spacing={4}
      flexDirection="column"
      bg="gray.50"
      borderRadius={4}
      border="1px solid"
      borderColor="gray.200"
    >
      <Flex alignItems="center" gap={2}>
        <Text textStyle="body1Medium">
          {skill?.name || skillBadge?.skill?.name}
        </Text>
        <Flex alignItems="center" gap={4}>
          <HStack spacing={2} divider={<StackDivider borderColor="gray.500" />}>
            <Text
              textStyle="body1Medium"
              color={
                currentScreen === ScreensEnum.RATING
                  ? 'primary.500'
                  : 'gray.500'
              }
              cursor="pointer"
              onClick={() => setCurrentScreen(ScreensEnum.RATING)}
            >
              Rating
            </Text>
            {size(employmentSkillBadgesToUse) ||
            currentScreen === ScreensEnum.WORK_HISTROY ? (
              <Text
                textStyle="body1Medium"
                color={
                  currentScreen === ScreensEnum.WORK_HISTROY
                    ? 'primary.500'
                    : 'gray.500'
                }
                cursor="pointer"
                onClick={() => setCurrentScreen(ScreensEnum.WORK_HISTROY)}
              >
                Work history
              </Text>
            ) : null}
            {currentScreen === ScreensEnum.EMPLOYMENT_DETAILS ||
            size(employmentSkillBadgesToUse) ? (
              <Flex alignItems="center" gap={1}>
                <Text
                  textStyle="body1Medium"
                  color={
                    currentScreen === ScreensEnum.EMPLOYMENT_DETAILS
                      ? 'primary.500'
                      : 'gray.500'
                  }
                  cursor="pointer"
                  onClick={() =>
                    setCurrentScreen(ScreensEnum.EMPLOYMENT_DETAILS)
                  }
                >
                  Experience
                </Text>
                {employmentSkillBadgesToUse.map(
                  (employmentSkillBadge, index) => (
                    <Box
                      key={employmentSkillBadge.id}
                      height="fit-content"
                      py={0}
                      px={1.5}
                      borderRadius="base"
                      border="1px solid"
                      borderColor="primary.500"
                      bg={
                        index > currentEmploymentSkillBadgeIndex ||
                        currentScreen !== ScreensEnum.EMPLOYMENT_DETAILS
                          ? 'white'
                          : 'primary.500'
                      }
                      cursor={
                        skillBadge?.id ||
                        (employmentSkillBadge.usage &&
                          employmentSkillBadge.usage !==
                            EmploymentSkillBadgeUsageTypes.NONE)
                          ? 'pointer'
                          : 'auto'
                      }
                      onClick={() =>
                        handleEmploymentSkillBadgeClick(
                          employmentSkillBadge,
                          index
                        )
                      }
                    >
                      <Text
                        textStyle="body2Medium"
                        color={
                          index > currentEmploymentSkillBadgeIndex ||
                          currentScreen !== ScreensEnum.EMPLOYMENT_DETAILS
                            ? 'primary.500'
                            : 'white'
                        }
                      >
                        {index + 1}
                      </Text>
                    </Box>
                  )
                )}
              </Flex>
            ) : null}
          </HStack>
        </Flex>
      </Flex>

      {currentScreen === ScreensEnum.EMPLOYMENT_DETAILS ? (
        <LoadingSkeleton loaded={newEmploymentSkillBadgeIsFetched} count={3}>
          <AlertMessage message={employmentSkillBadgesErrorMessage} />
          {size(employmentSkillBadgesToUse) ? (
            <AddEditEmploymentSkillBadgeForm
              employmentSkillBadgeCacheKey={EmploymentSkillBadgesCache.skillBadgeIndexCacheKey(
                currentSkillBadgeId as EmploymentSkillBadgeSkillBadgeId
              )}
              employmentSkillBadge={
                employmentSkillBadgesToUse[currentEmploymentSkillBadgeIndex]
              }
              skillBadgesCacheKey={skillBadgesCacheKey}
              onSaveEmploymentSkillBadges={onAdd}
              onNextEmploymentSkillBadge={handleNextEmploymentSkillBadge}
              onDiscard={handleDiscard}
              onCancel={onDiscard}
              isEditing={!!skillBadge?.nanoId}
              isDiscarding={deleteSkillBadgeIsLoading}
              discardErrorMessage={deleteSkillBadgeErrorMessage}
              lastEmploymentSkillBadgeId={
                last(employmentSkillBadgesToUse)?.id as EmploymentSkillBadgeId
              }
            />
          ) : null}
        </LoadingSkeleton>
      ) : (
        <Fragment>
          <Flex gap={4} alignItems="flex-start" flexWrap="wrap">
            {currentScreen === ScreensEnum.RATING ? (
              <Flex alignItems="center" flex={1}>
                <SelectField
                  control={control}
                  placeholder="Rate yourself in this skill"
                  name={SkillBadgeFields.RATING}
                  options={ratingOptions}
                  errorMessage={validationErrors.ratingValidationError}
                  autoFocus={true}
                />
              </Flex>
            ) : null}

            {currentScreen === ScreensEnum.WORK_HISTROY ? (
              <Flex alignItems="center" flex={1}>
                <SelectEmploymentsFormField
                  control={control}
                  placeholder="Where job skill was applied (maximum of 5)"
                  jobResumeRecruiterId={jobResumeRecruiterId}
                  item={{
                    employments: employmentSkillBadges.map(
                      (employmentSkillBadge) => employmentSkillBadge.employment
                    )
                  }}
                  errorMessage={validationErrors.employmentIdsValidationError}
                  maxNumberOfEmployments={5}
                  withSelectAllButton
                />
              </Flex>
            ) : null}
          </Flex>

          <Flex>
            <ButtonGroup ml="auto" gap={4}>
              {skillBadge ? (
                <PureButtonHelper
                  size="medium"
                  hierarchy="unstyled"
                  color="grey.900"
                  i18nText="Cancel"
                  onClick={onDiscard}
                />
              ) : (
                <PureButtonHelper
                  size="medium"
                  hierarchy="unstyled"
                  color="red.900"
                  i18nText="Discard"
                  isLoading={deleteSkillBadgeIsLoading}
                  onClick={handleDiscard}
                />
              )}
              <PureButtonHelper
                size="medium"
                i18nText={skillBadge ? 'Update' : 'Add'}
                isLoading={
                  addEditSkillBadgeFormIsLoading ||
                  createSkillBadgeIsLoading ||
                  updateSkillBadgeIsLoading ||
                  createEmploymentSkillBadgeIsLoading
                }
                onClick={handleFormSubmit}
              />
            </ButtonGroup>
          </Flex>

          <AlertMessage
            message={
              addEditSkillBadgeFormErrorMessage ||
              createSkillBadgeErrorMessage ||
              updateSkillBadgeErrorMessage ||
              deleteSkillBadgeErrorMessage ||
              createEmploymentSkillBadgeErrorMessage
            }
          />
        </Fragment>
      )}
    </Stack>
  );
}

export default AddEditApplicantSkillBadgeForm;
