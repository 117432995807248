import { ReactNode } from 'react';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  JobCandidateAssessmentFields,
  JobCandidateAssessmentMessage
} from '../../../../../jobCandidateAssessments/jobCandidateAssessmentsTypes';
import { AddJobCandidateAssessmentFormData } from '../../../../../jobCandidateAssessments/components/forms/AddJobCandidateAssessmentForm/AddJobCandidateAssessmentForm.types';
import { Control, UseFormRegisterReturn } from 'react-hook-form';
import { JobNanoId } from '../../../../jobsTypes';
import { ErrorMessage } from '../../../../../../types';

export type ViewJobCandidateDetailsSendAssessmentContextType = {
  totalSteps: number;
  currentStep: number;
  isLastStep: boolean;
  isFirstStep: boolean;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  sendPhoneAssessment: () => void;
  sendEmailAssessment: () => void;
  isEmailAssessmentSent: boolean;
  isSendEmailAssessmentOpen: boolean;
  isSendPhoneAssessmentOpen: boolean;
  openEmailAssessmentSent: () => void;
  cancelEmailAssessmentSent: () => void;
  openSendEmailAssessment: () => void;
  closeSendEmailAssessment: () => void;
  closeSendPhoneAssessment: () => void;
  openSendPhoneAssessment: () => void;
  assessmentFields: ViewJobCandidateDetailsSendAssessmentFields[];
  handleAssessmentField: (
    field: ViewJobCandidateDetailsSendAssessmentFields
  ) => void;
  message: JobCandidateAssessmentMessage;
  errorMessage: ErrorMessage;
  validationErrors: Partial<{
    messageValidationError: string;
  }>;
  control: Control<AddJobCandidateAssessmentFormData>;
  registerFields: {
    registerMessage: UseFormRegisterReturn<JobCandidateAssessmentFields.MESSAGE>;
  };
};

export type ViewJobCandidateDetailsSendAssessmentProviderProps = {
  jobCandidateNanoId: JobCandidateNanoId;
  jobNanoId: JobNanoId;
  children: ReactNode;
};

export enum ViewJobCandidateDetailsSendAssessmentFields {
  AssessmentPreferences = 'AssessmentPreferences',
  // AssessmentQualifications = 'AssessmentQualifications',
  AssessmentSkillsExpertise = 'AssessmentSkillsExpertise',
  AssessmentQuestions = 'AssessmentQuestions',
  AssessmentProjects = 'AssessmentProjects',
  AssessmentReferences = 'AssessmentReferences',
  AssessmentUpdatedResume = 'AssessmentUpdatedResume',
  AssessmentMyNxmoov = 'AssessmentMyNxmoov'
}
