import { ReactNode } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { SystemProps, SystemStyleObject } from '@chakra-ui/react';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import {
  FetchJobFunctionsCacheKey,
  JobFunctionId,
  JobFunctionName
} from '../../../../jobFunctions/jobFunctionsTypes';

import {
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath,
  SelectFieldReactHookFormRules
} from '../../../../../helpers/forms/formFields/SelectField';

import {
  SelectJobFunctionFormFieldControl,
  SelectJobFunctionFormFieldControlWithAssociatedJobTitleProps,
  SelectJobFunctionFormFieldControlWithoutAssociatedJobTitleProps
} from './components/SelectJobFunctionFormFieldControl';
import { JobId } from '../../../../jobs/jobsTypes';

type SelectJobFunctionFormFieldRequiredProps<T extends FieldValues> = {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: SelectFieldReactHookFormControl<T>;
  rules?: SelectFieldReactHookFormRules<T>;
  name?: SelectFieldReactHookFormFieldPath<T>;
  item?: {
    jobFunction: {
      id: JobFunctionId;
      name: JobFunctionName;
    } | null;
  } | null;
  errorMessage: ErrorMessage;
  labelAddon?: ReactNode;
  label?: string;
  placeholder?: string;
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
  visibility?: SystemProps['visibility'];
  withoutUpdateJobTitle?: boolean;
  withoutLabel?: boolean;
  cacheKey?: FetchJobFunctionsCacheKey;
  onChangeValue?: (value: string | number | null) => void;
  unsetValue?: boolean;
  jobId?: JobId;
} & (
  | SelectJobFunctionFormFieldControlWithAssociatedJobTitleProps
  | SelectJobFunctionFormFieldControlWithoutAssociatedJobTitleProps
);

function SelectJobFunctionFormField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  rules,
  name = 'jobFunctionId' as SelectFieldReactHookFormFieldPath<T>,
  item,
  errorMessage,
  labelAddon,
  cacheKey,
  label,
  placeholder,
  selectedFunctionId,
  selectedJobTitleId,
  selectDisciplineId,
  selectedDisciplineId,
  reactSelectStyles,
  isBorderless,
  visibility,
  withoutLabel,
  withoutUpdateJobTitle,
  onChangeValue,
  unsetValue = true,
  jobId
}: SelectJobFunctionFormFieldRequiredProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange } }) => (
        <SelectJobFunctionFormFieldControl
          isRequired={isRequired}
          isDisabled={isDisabled}
          label={label}
          withoutLabel={withoutLabel}
          labelAddon={labelAddon}
          placeholder={placeholder}
          onChange={(value) => {
            onChange(value);
            onChangeValue?.(value);
          }}
          name={name}
          errorMessage={errorMessage}
          item={item}
          cacheKey={cacheKey}
          reactSelectStyles={
            isBorderless
              ? {
                  control: (base: SystemStyleObject) => ({
                    ...base,
                    borderColor: 'transparent',
                    p: 0
                  })
                }
              : reactSelectStyles
          }
          visibility={visibility}
          selectedFunctionId={
            selectedFunctionId as SelectJobFunctionFormFieldControlWithoutAssociatedJobTitleProps['selectedFunctionId']
          }
          selectedJobTitleId={
            selectedJobTitleId as SelectJobFunctionFormFieldControlWithoutAssociatedJobTitleProps['selectedJobTitleId']
          }
          selectDisciplineId={
            selectDisciplineId as SelectJobFunctionFormFieldControlWithoutAssociatedJobTitleProps['selectDisciplineId']
          }
          selectedDisciplineId={
            selectedDisciplineId as SelectJobFunctionFormFieldControlWithoutAssociatedJobTitleProps['selectedDisciplineId']
          }
          withoutUpdateJobTitle={withoutUpdateJobTitle}
          unsetValue={unsetValue}
          jobId={jobId}
        />
      )}
    />
  );
}

export default SelectJobFunctionFormField;
