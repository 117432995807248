import { FetchJobCandidateAssessmentsCacheKey } from '../../jobCandidateAssessmentsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  JobCandidateAssessmentsBffRequests,
  CreateJobCandidateAssessmentRequestData,
  CreateJobCandidateAssessmentErrorResponse
} from '../../JobCandidateAssessmentsBffRequests';

interface CreateJobCandidateAssessmentOptions {
  cacheKeys?: FetchJobCandidateAssessmentsCacheKey[];
}

function useCreateJobCandidateAssessment({
  cacheKeys = []
}: CreateJobCandidateAssessmentOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateJobCandidateAssessmentRequestData,
    CreateJobCandidateAssessmentErrorResponse
  >({
    mutationFn: (queryInput) =>
      JobCandidateAssessmentsBffRequests.createJobCandidateAssessment(
        queryInput
      ),
    cacheKeys
  });

  return {
    createJobCandidateAssessmentData: createItemData,
    createJobCandidateAssessmentError: createItemError,
    createJobCandidateAssessmentErrorMessage: createItemErrorMessage,
    createJobCandidateAssessmentIsLoading: createItemIsLoading,
    createJobCandidateAssessment: createItem,
    createJobCandidateAssessmentReset: createItemReset
  };
}

export default useCreateJobCandidateAssessment;
