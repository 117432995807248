import { Flex, HStack, Stack } from '@chakra-ui/react';
import { Text } from '../../../../../../../../helpers/Text';
import { TextareaField } from '../../../../../../../../helpers/forms/formFields/TextareaField';
import { EyeIcon } from '../../../../../../../../icons/EyeIcon';
import { FilesIcon } from '../../../../../../../../icons/FilesIcon';
import { PreferencesIcon } from '../../../../../../../../icons/PreferencesIcon';
import { ProjectsIcon } from '../../../../../../../../icons/ProjectsIcon';
import { QuestionMarkIcon } from '../../../../../../../../icons/QuestionMarkIcon';
import { ReferencesIcon } from '../../../../../../../../icons/ReferencesIcon';
import { SettingsIcon } from '../../../../../../../../icons/SettingsIcon';
import { JobCandidateAssessmentFields } from '../../../../../../../jobCandidateAssessments/jobCandidateAssessmentsTypes';
import {
  useViewJobCandidateDetailsSendAssessmentContext,
  ViewJobCandidateDetailsSendAssessmentFields
} from '../../../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import ViewJobCandidateDetailsAssessmentItem from '../ViewJobCandidateDetailsAssessmentItem/ViewJobCandidateDetailsAssessmentItem';

export default function ViewJobCandidateDetailsAssessmentSelection() {
  const { registerFields, message, validationErrors } =
    useViewJobCandidateDetailsSendAssessmentContext();

  return (
    <Stack spacing={2}>
      <ViewJobCandidateDetailsAssessmentItem
        label="Preferences"
        icon={PreferencesIcon}
        value={
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentPreferences
        }
      />

      <ViewJobCandidateDetailsAssessmentItem
        label="Skills Expertise"
        icon={SettingsIcon}
        value={
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentSkillsExpertise
        }
      />

      <ViewJobCandidateDetailsAssessmentItem
        label="Questions"
        icon={QuestionMarkIcon}
        value={ViewJobCandidateDetailsSendAssessmentFields.AssessmentQuestions}
      />

      <ViewJobCandidateDetailsAssessmentItem
        label="Projects"
        icon={ProjectsIcon}
        value={ViewJobCandidateDetailsSendAssessmentFields.AssessmentProjects}
      />

      <ViewJobCandidateDetailsAssessmentItem
        label="References"
        icon={ReferencesIcon}
        value={ViewJobCandidateDetailsSendAssessmentFields.AssessmentReferences}
      />

      <ViewJobCandidateDetailsAssessmentItem
        label="Updated Resume"
        icon={FilesIcon}
        value={
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentUpdatedResume
        }
      />

      <Stack spacing={2}>
        <Text textStyle="body1Medium">Add a message</Text>

        <TextareaField
          rows={6}
          defaultValue={message}
          name={JobCandidateAssessmentFields.MESSAGE}
          errorMessage={validationErrors.messageValidationError}
          onChange={registerFields.registerMessage.onChange}
          ref={registerFields.registerMessage.ref}
        />
      </Stack>

      <Stack p={4} bg="gray.100" spacing={1.5} borderRadius="base">
        <HStack spacing={2} alignItems="flex-start">
          <Flex h={5} alignItems="center">
            <EyeIcon />
          </Flex>

          <Text color="gray.500" textStyle="body2Medium">
            Activities, Pipelines, Scorecards and Tasks are{' '}
            <Text as="span" color="gray.700">
              not visible
            </Text>{' '}
            to candidates
          </Text>
        </HStack>
      </Stack>
    </Stack>
  );
}
