import { Box } from '@chakra-ui/react';
import React from 'react';

export default function ViewJobCandidateDetailsPageOverlay() {
  return (
    <Box
      w="full"
      h="full"
      top={0}
      left={0}
      zIndex={9}
      cursor="not-allowed"
      pointerEvents="auto"
      pos="absolute"
    />
  );
}
