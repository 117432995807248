import {
  JobCandidateFirstName,
  JobCandidateId,
  JobCandidateJobTitle,
  JobCandidateLastName,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateSearchTypeLabel,
  MayBeJobCandidateCompany,
  MayBeJobCandidateImage
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../jobsTypes';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageWorkflowStage
} from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsStageTasksWidgetProvider } from '../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';
import { ViewJobCandidateDetailsStageTasksManagerWrapper } from './components/ViewJobCandidateDetailsStageTasksManagerWrapper';

interface ViewJobCandidateDetailsStageTasksManagerProps {
  jobCandidateNanoId: JobCandidateNanoId;
  requiresCredit?: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
  jobCandidate: {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    firstName?: JobCandidateFirstName;
    lastName?: JobCandidateLastName;
    jobTitle: JobCandidateJobTitle;
    company: MayBeJobCandidateCompany;
    image: MayBeJobCandidateImage;
    searchTypeLabel: JobCandidateSearchTypeLabel;
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
  jobCandidateDetails?: JobCandidateDetailsJobCandidate;
  isHiringPortalPath?: boolean;
  isCentered?: boolean;
}

export default function ViewJobCandidateDetailsStageTasksManager({
  job,
  isCentered,
  jobCandidate,
  isHiringPortalPath,
  jobCandidateNanoId,
  requiresCredit,
  jobCandidateDetails
}: ViewJobCandidateDetailsStageTasksManagerProps) {
  return (
    <ViewJobCandidateDetailsStageTasksWidgetProvider
      jobNanoId={job.nanoId}
      jobCandidate={jobCandidate}
      jobCandidateNanoId={jobCandidateNanoId}
    >
      <ViewJobCandidateDetailsStageTasksManagerWrapper
        job={job}
        isCentered={isCentered}
        jobCandidate={jobCandidate}
        isHiringPortalPath={isHiringPortalPath}
        jobCandidateNanoId={jobCandidateNanoId}
        requiresCredit={requiresCredit}
        jobCandidateDetails={jobCandidateDetails}
      />
    </ViewJobCandidateDetailsStageTasksWidgetProvider>
  );
}
